import { DEGREES, LOADING_DEGREES, LOADEN_DEGREES } from "../../constants/actionTypes";

export default (degrees = { degrees: [], loading: false }, action) => {
    switch (action.type) {
        case DEGREES:
            return { ...degrees, degrees: action.data, loading: false };
        case LOADING_DEGREES:
            return { ...degrees, loading: true };
        case LOADEN_DEGREES:
            return { ...degrees, loading: false };
        default:
            return degrees;
    }
}