import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { MAXIMUM_SALARY, LOADING_MAXIMUM_SALARY, LOADED_MAXIMUM_SALARY } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getMaxSalary = () => async(dispatch) => {
    dispatch({ type: LOADING_MAXIMUM_SALARY });
    try {
        var { data } = await api.getMaximumSalary();
        await dispatch({ type: MAXIMUM_SALARY, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_MAXIMUM_SALARY });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}