import React from 'react'

const ContactSection = ({contactUsInfo}) => {
    return (
        <div className="contact-card-section ptb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <i className='bx bx-phone-call'></i>
                                    <ul>
                                        {contactUsInfo?.map(item => item.phone)?.map((innerItem, i) => 
                                        <li key={i}><label>{innerItem}</label></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="contact-card">
                                    <i className='bx bx-mail-send' ></i>
                                    <ul>
                                        {contactUsInfo?.map(item => item.email)?.map((innerItem, i) => 
                                        <li key={i}><label>{innerItem}</label></li>
                                        )}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                                <div className="contact-card">
                                    <i className='bx bx-location-plus' ></i>
                                    <ul>
                                        {contactUsInfo?.map(item => item.address)?.map((innerItem, i) => 
                                        <li key={i}><label>{innerItem}</label></li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSection