import React from 'react'
import './JobTypes.css'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import newBanner from '../../../assets/section3.svg'
import circleShadow from '../../../assets/shadowEffect.svg'

const JobTypes = () => {
  const { t } = useTranslation();
  // const { job, loading } = useSelector(state => state.homeJob);
  const job = {data: [1,2,3,4,5,6]};
  const loading = false;
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  return (
    <section style={{width:'100%', position:'relative', display:'flex', alignItems:'center', justifyContent:'center', marginBottom: '50px', direction: lng === 'arab'? 'rtl': 'ltr'}}>
      <div className='white_blue_bc'/>
      <div className='home_banner'>
        <div className='left_content'>
          <h1 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("About")}</h1>
          <p style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("CV SA is a leader teacher recruitment through a commitment to placing the world’s best educators in leading educational institutions around the globe.")}</p>
        </div>
        <div style={{justifyContent:'flex-end', zIndex:'10'}} className='right_content'>
          <img src={newBanner} alt="new_banner" className='about_banner'/>
        </div> 
      </div>     
    </section>
  )
}

export default JobTypes