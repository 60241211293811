import React, { useEffect, useState } from "react";
import ReusableBanner from "../ui/reusableBanner/ReusableBanner";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import './Subjects.css'
import { Link } from "react-router-dom";
import Spinner from "../ui/spinner/Spinner";
import NoDataFound from "../ui/noDataFound/NoDataFound";
import defaultLogo from '../../assets/logo.svg'
const Subject = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { subject, loading } = useSelector(state => state.homeSubject);
  useEffect(() => {
    console.log('subject', subject?.data)
  }, [subject])
  return (
    <div style={{ direction: lng === "arab" ? "rtl" : "ltr" }} className="pages_with_header">
      <ReusableBanner title={t("Subjects")} bacgroundClass="teacherBC"/>
      <section className="subjects pb-70 pt-40 blog-style-two">
        <div className="container">
          {loading?
          <Spinner/>:
          subject?.data?.filter(item => item?.jobs_sum_number_of_vacancies).length === 0?
          <NoDataFound/>:  
          <div className="row">
          {subject?.data?.filter(item => item?.jobs_sum_number_of_vacancies).map((item, i) => 
            <div className="col-lg-3 col-md-4 col-sm-6">
              <Link to={`/jobs?sub=${item?.id}`}>
                <div class="category-card">
                  <img src={item?.icon || defaultLogo} alt='subject_icon' className='subject_icon'/>:
                  <h3>{item?.name}</h3>
                  <p>{(item?.jobs_sum_number_of_vacancies || 0) + ' ' + t('open position')}</p>
                </div>
              </Link>
            </div>
          )}
          </div>}
        </div>
      </section>
    </div>
  );
};

export default Subject;
