import { t } from 'i18next';
import { LOGOUT } from '../constants/actionTypes'

export default function checkErr(error, setLoadingModalShow, toastAlert, option, dispatch) {
    if (error?.response?.status === 400) {
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
      if (toastAlert) {
        toastAlert(t(error?.response?.data?.message), undefined, "warning");
      }
      if (option) {
        option()
      }
    } else if (error?.response?.status === 401) {
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
      if (toastAlert) {
      toastAlert(t("Please login again to continue"), undefined, "warning")
      }
      dispatch({ type: LOGOUT })
    } else {
      if (toastAlert) {
        toastAlert(t("Something Went Wrong!"), undefined, "warning");
      }
      if (setLoadingModalShow) {
        setLoadingModalShow(false)
      }
    }
}