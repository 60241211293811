import React, {useEffect} from 'react'
import { useHistory } from 'react-router'
import logoPlaceholder from '../../../assets/whiteLogo.svg'
import { useTranslation } from 'react-i18next'
import './footer.css'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
const Footer = () => {
	const history = useHistory()
	const { t } = useTranslation();
	let lng = JSON.parse(localStorage.getItem("language"))?.lang;
	const { subject, loading } = useSelector(state => state.homeSubject);
	const popularCities = useSelector(state => state.popular_cities.popularCities);
	const popularCitiesLoading = useSelector(state => state.popular_cities.loading);
	const footerJobTypes = useSelector(state => state.footerJobTypes.footerJobTypes);
	const footerJobTypesLoading = useSelector(state => state.footerJobTypes.loading);
	return (
		<footer style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
			<div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className="upper_footer_icon_area pb-70">
				<div className="new_footer_widget">
					<div className="footer-logo">
						<a >
						    <img src={logoPlaceholder} className='new_footer_logo' alt="logo" />
					    </a>
					</div>
					<div className="footer_icons_wrapper">
						<a target="_blank"><i className='bx bxl-facebook'></i></a>
						<a target="_blank"><i className='bx bxl-twitter'></i></a>
						<a target="_blank"><i className='bx bxl-pinterest-alt'></i></a>
						<a target="_blank"><i className='bx bxl-linkedin'></i></a>
					</div>
				</div>
				<div className="new_footer_widget">
					<label>{t("Job Types")}</label>
					{footerJobTypesLoading?
					null:
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						{footerJobTypes?.filter(item => item?.jobs_sum_number_of_vacancies)?.slice(0,5)?.map((innerItem,i) => 
							<li key={i}><Link to={`/jobs?typ=${innerItem?.id}`} className='uk-button uk-button-text'>{innerItem?.name}</Link></li>
						)}
					</ul>}
				</div>
				<div className="new_footer_widget">
					<label>{t("Subjects")}</label>
					{loading?
					null:
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						{subject?.data?.filter(item => item?.jobs_sum_number_of_vacancies)?.slice(0,5)?.map((innerItem,i) => 
							<li key={i}><Link to={`/jobs?sub=${innerItem?.id}`} className='uk-button uk-button-text'>{innerItem?.name}</Link></li>
						)}
					</ul>}
				</div>
				<div className="new_footer_widget">
					<label>{t("Schools by city")}</label>
					{popularCitiesLoading?
					null:
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						{popularCities?.filter(item => item?.count)?.slice(0,5)?.map((innerItem,i) => 
							<li key={i}><Link to={`/schools?cit=${innerItem?.city}`} className='uk-button uk-button-text'>{innerItem?.city}</Link></li>
						)}
					</ul>}
				</div>
				<div className="new_footer_widget">
					<label>{t("Company")}</label>
					<ul style={{ padding: lng === 'arab' ? '0px' : null }}>
						<li><Link to={`/about`} className='uk-button uk-button-text'>{t("About")}</Link></li>
						<li><Link to={`/contact-us`} className='uk-button uk-button-text'>{t("Contact us")}</Link></li>
					</ul>
				</div>
			</div>
			<div className='copyWrites'>
				<label>{t("© CV SA 2022 All Rights Reserved.")}</label>
			</div>
		</footer>
	)
}

export default Footer