import { LOAD_SINGLE_ARTICLE, FETCH_SINGLE_ARTICLE, SINGLE_ARTICLE_LOADED } from '../constants/actionTypes'
import * as api from '../../api'

export const getSingleArticle = (lang, artID) => async (dispatch) => {
    dispatch({ type: LOAD_SINGLE_ARTICLE });
    try {
        const { data } = await api.getArticleDetails(lang, artID);
        console.log(data)
        dispatch({ type: FETCH_SINGLE_ARTICLE, payload: data.data })
    } catch (error) {
        dispatch({ type: SINGLE_ARTICLE_LOADED })
    }
}
