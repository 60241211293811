import { slide as Menu } from "react-burger-menu";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./SideMenu.css";
import MenuIcon from "../../../../assets/menu.png";
import { useTranslation } from "react-i18next";

function SideMenu({logOut}) {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const Inner = (str) => {
    if (str === "eng") {
      localStorage.setItem("language", JSON.stringify({ lang: "en" }));
      window.location.reload();
    } else {
      localStorage.setItem("language", JSON.stringify({ lang: "arab" }));
      window.location.reload();
    }
  };
  var styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '30px',
      height: '25px',
      left: lng === 'arab' ? 'auto' : '25px',
      right: lng === 'arab' ? '15px' : 'auto',
      transform: lng === 'arab' ? 'scaleX(-1)' : 'unset',
      top: '21px',
    },
    bmMenuWrap: {
      position: 'fixed',
      maxWidth: '80vw',
      top: '0',
      left: lng !== 'arab' ? '0' : 'auto',
      height: '100%',
    }
  }
  return (
    <Menu
      styles={styles}
      isOpen={menuOpen}
      right={lng === 'arab' ? true : false}
      className="naviForNavi"
      customBurgerIcon={<img src={MenuIcon} alt="mobile_menu" />}
    >
      <div className='sideMenu_links' style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "flex-start", width: "100%" }}>
        <button style={
          lng === "arab" ? { direction: "rtl", textAlign: "right" } : null
        } onClick={() => history.push("/jobs")} className="menu_item">
          {t('Job Openings')}
        </button>
        <button style={
          lng === "arab" ? { direction: "rtl", textAlign: "right" } : null
        } onClick={() => history.push("/subjects")} className="menu_item">
          {t("Subjects")}
        </button>
        <button style={
          lng === "arab" ? { direction: "rtl", textAlign: "right" } : null
        } onClick={() => history.push("/schools")} className="menu_item">
          {t("Schools")}
        </button>
        <button style={
          lng === "arab" ? { direction: "rtl", textAlign: "right" } : null
        } onClick={() => history.push("/blogs")} className="menu_item">
          {t("Blog")}
        </button>
        <button style={
          lng === "arab" ? { direction: "rtl", textAlign: "right" } : null
        } onClick={() => history.push("/authentication")} className="menu_item">
          {t("Account")}
        </button>
        {localStorage.getItem("sess")?
        <button onClick={() => logOut()}
          style={lng === "arab" ? { direction: "rtl", textAlign: "right" } : null}  className="menu_item">
          {t("Logout")}
        </button>:null}
        <button
          style={lng === "arab" ? { direction: "rtl", textAlign: "right" } : null}
          onClick={() => Inner(lng === 'arab' ? 'eng' : 'arab')} className="menu_item">
          {lng === 'arab' ? 'English' : 'العربية'}
        </button>
      </div>
    </Menu>
  );
}
export default SideMenu;
