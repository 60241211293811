import { FETCH_AVAILABLE_JOB, AVAILABLE_JOB_LOADED, LOAD_AVAILABLE_JOB } from '../../constants/actionTypes'

export default (availableJob = { availableJob: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_AVAILABLE_JOB:
            return { ...availableJob, availableJob: action.payload, loading: false };
        case LOAD_AVAILABLE_JOB:
            return { ...availableJob, loading: true };
        case AVAILABLE_JOB_LOADED:
            return { ...availableJob, loading: false };
        default:
            return availableJob;
    }
}