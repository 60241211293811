import React from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "../components/ui/spinner/Spinner";

function LoadingModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="popUpLoading"
    >
      <Modal.Body>
        <Spinner/>
      </Modal.Body>
    </Modal>
  );
}
export default LoadingModal;
