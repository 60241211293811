import React, { useEffect } from 'react'
import './Job_Item.css'
import logoDefault from '../../../assets/logo.png'
import locationIcon from '../../../assets/locationIcon.png'
import { useHistory } from 'react-router'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

const JobItem = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const history = useHistory()
    return (
        <div style={{ borderTop: props.noBorderTop ? 'none' : null }} className='job_list_item'>
            <div className='img_content_wrapper'>
                <img style={{borderRadius: '50%'}} src={ props?.data?.academy?.avatar || props?.avatar || logoDefault} alt='job_title' className='academy_img_as_item' />
                <div style={{ textAlign: lng === 'arab' ? 'right' : 'left' }} className='job_item_content_wrapper'>
                    <Link to={props.jobDetailsPage? '#':`/job-details/${props?.data?.id}`} style={{fontSize:'16px', fontWeight:'900'}}>
                       ح {props.data?.title}
                    </Link>
                    <Link style={{color:'#008C77', fontWeight:'bold'}} to={`/job-details/id=${props?.data?.academy?.id || props?.data?.id}`} className='academy_name'>{props.data?.academy?.name || props?.name}</Link>
                    <div className='location'>
                        <label className='job_locations'>{props.data?.location?.city || t("N/A")}</label>
                    </div>
                    <label className='job_locations'>
                        {t("Posted on:") + ' '}
                        {
                        !props.data?.start_date?
                        t("N/A"):
                        (t(new Intl.DateTimeFormat('en-US', { weekday: 'long'}).format(new Date(props.data?.start_date)))
                        + ' ' + 
                        props.data?.start_date?.split('-')?.reverse()?.shift()
                        + ' ' + 
                        t(new Intl.DateTimeFormat('en-US', { month: 'long'}).format(new Date(props.data?.start_date))))
                        }
                    </label>
                </div>
            </div>
            <div className='job_item_salary'>
                {props.jobDetailsPage?
                null:
                <Link to={`/job-details/${props?.data?.id}`} className='job_statue'>{props.userDetails?.type === 'A'? t("View"):t('Apply Now')}</Link>
                }
            </div>
        </div>
    )
}

export default JobItem