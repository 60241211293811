import React from 'react'
import Banner from './banner/Banner'
import PopularSubs from './popularSubs/PopularSubs'
import AvailableJobs from './availableJobs/AvailableJobs'
import JobTypes from './jobTypes/JobTypes'
import CreateBuild from './createNbuild/CreateBuild'
import News from './news/News'
import ForSchools from './forSchools/ForSchools'
import { useSelector } from 'react-redux'
import './Home.css'
import Spinner from '../ui/spinner/Spinner'
const Home = () => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const userDetails = useSelector((state) => state?.userDetails?.authData);
  const loading = useSelector((state) => state?.userDetails?.loading);
  return (
    <div className='home_page'>
      <Banner />
      {userDetails?.type === 'A'?
      null:
      <CreateBuild userDetails={userDetails} loading={loading} />
      }
      {userDetails?.type === 'A'?
      null:
      <ForSchools userDetails={userDetails} loading={loading} />
      }
      <AvailableJobs />
      <PopularSubs userDetails={userDetails} loading={loading} />
      <News />
      <JobTypes />
    </div>
  )
}

export default Home