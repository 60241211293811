import React, { useState, useEffect } from 'react'
import './JobsList.css'
import JobItem from '../job_listItem/Job_Item'
import JobFilterModal from '../jobs_filter/job_filter_modal/JobFilterModal'
import filterIcon from '../../../assets/filter.png'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../../ui/noDataFound/NoDataFound'
import Spinner from '../../ui/spinner/Spinner'
import { useSelector } from 'react-redux'
import { getJobs } from '../../../redux/actions/jobs'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import JobsPagination from '../../ui/pagination/JobsPagination'
const JobsList = ({ 
    typeURL,
    subjectURL,
    job,
    loading, 
    currentPage,
    nextPage,
    lastPage,
    setInputSearch,
    inputSearch,
    setFilterModal,
    setSortBy,
    sortBy
}) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const dispatch = useDispatch();
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        
        <div className='jobs_list blog-style-two'>
            <div className="row">
                <div className="col-lg-9 col-sm-8 order-lg-1">
                    <label style={{ textAlign: lng === "arab" ? "right" : "left" }} className="filter_title">
                        {t("Search Keywords")}
                    </label>
                    <div className="uk-margin">
                        <input
                            value={inputSearch}
                            onChange={(e) => setInputSearch(e.target.value)}
                            className="form-control"
                            type="text"
                            placeholder={t("Academy Name Or Job Title")}
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-sm-4 order-lg-1">
                <label style={{ textAlign: lng === "arab" ? "right" : "left" }} className="filter_title">
                    {t("Sort by")}
                </label>
                <div className="uk-margin">
                    <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className='form-control'>
                        <option value={''}>{t('Most Recent')}</option>
                        <option value={'name'}>{t('Name')}</option>
                        <option value={'salary'}>{t('Salary')}</option>
                    </select>
                </div>
                </div>
            </div>
            <div className='job_list_header'>
                <div className='mobile_filter_header'>
                    <button onClick={() => setFilterModal(true)}>
                        <img src={filterIcon} alt='filter_icon' />
                        {t("Filter")}
                    </button>
                </div>
            </div>
            <>
                {loading? 
                <Spinner/>:
                !loading && job?.length === 0 ? 
                <NoDataFound />:
                <>
                    {job?.map((item, index) => (
                        <JobItem colorOfText='red' data={item} key={index} />
                    ))}
                </>}
                </>
            
            {lastPage === 1?
            null:
            <JobsPagination
                currentPage={currentPage}
                nextPage={nextPage}
                lastPage={lastPage}
                typeURL={typeURL}
                subjectURL={subjectURL}
                loading={loading}
            />}
        </div>
    )
}

export default JobsList