import { CITIZENSHIPS, LOADING_CITIZENSHIPS, LOADED_CITIZENSHIPS } from "../../constants/actionTypes";

export default (citizenships = { citizenships: [], loading: false }, action) => {
    switch (action.type) {
        case CITIZENSHIPS:
            return { ...citizenships, citizenships: action.data, loading: false };
        case LOADING_CITIZENSHIPS:
            return { ...citizenships, loading: true };
        case LOADED_CITIZENSHIPS:
            return { ...citizenships, loading: false };
        default:
            return citizenships;
    }
}