import { ACADEMY_DETAILS, LOADED_ACADEMY_DETAILS, LOADING_ACADEMY_DETAILS } from '../../constants/actionTypes'

export default (academyDetails = { academy: {}, loading: false }, action) => {
    switch (action.type) {
        case ACADEMY_DETAILS:
            return { ...academyDetails, academy: action.data, loading: false };
        case LOADING_ACADEMY_DETAILS:
            return { ...academyDetails, loading: true };
        case LOADED_ACADEMY_DETAILS:
            return { ...academyDetails, loading: false };
        default:
            return academyDetails;
    }
}