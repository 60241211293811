import * as api from "../../api/registeration";
import { toastAlert } from "../../layouts/nav/toastAlert";
import { JOB_TITLES, LOADING_JOB_TITLES, LOADED_JOB_TITLES, LOADING_CITIZENSHIPS, LOADED_CITIZENSHIPS, CITIZENSHIPS, LOADING_CURRICULUMS, LOADED_CURRICULUMS, CURRICULUMS, LOADING_DEGREES, LOADEN_DEGREES, DEGREES, LOADING_GENDER, LOADED_GENDER, GENDER, LOADING_GRADES, LOADED_GRADES, GRADES, LOADING_LANGUAGES, LOADED_LANGUAGES, LANGUAGES, LOADING_SUBJECTS, LOADED_SUBJECTS, SUBJECTS, LICENSE, LOADING_LICENSE, LOADED_LICENSE } from "../constants/actionTypes";
import checkErr from "./api_error_func";
import { t } from "i18next";

export const getJobTitle = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_JOB_TITLES });
    try {
      var {data} = await api.getJobTitles(lng);
      await dispatch({ type: JOB_TITLES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_JOB_TITLES });
    }
  };

  export const getCitizenships = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_CITIZENSHIPS });
    try {
      var {data} = await api.getCitizenships(lng);
      await dispatch({ type: CITIZENSHIPS, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_CITIZENSHIPS });
    }
  };

  export const getCurriculums = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_CURRICULUMS });
    try {
      var {data} = await api.getCurriculums(lng);
      await dispatch({ type: CURRICULUMS, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_CURRICULUMS });
    }
  };

  export const getDegreeLevels = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_DEGREES });
    try {
      var {data} = await api.getDegreeLevels(lng);
      await dispatch({ type: DEGREES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADEN_DEGREES });
    }
  };

  export const getGender = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_GENDER });
    try {
      var {data} = await api.getGender(lng);
      await dispatch({ type: GENDER, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_GENDER });
    }
  };

  export const getGrades = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_GRADES });
    try {
      var {data} = await api.getGrades(lng);
      await dispatch({ type: GRADES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_GRADES });
    }
  };

  export const getLanguages = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_LANGUAGES });
    try {
      var {data} = await api.getLanguages(lng);
      await dispatch({ type: LANGUAGES, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_LANGUAGES });
    }
  };

  export const getSubjects = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_SUBJECTS });
    try {
      var {data} = await api.getSubjects(lng);
      await dispatch({ type: SUBJECTS, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_SUBJECTS });
    }
  };

  export const getLicenseTypes = (lng) => async (dispatch) => {
    dispatch({ type: LOADING_LICENSE });
    try {
      var {data} = await api.getLicenseTypes(lng);
      await dispatch({ type: LICENSE, data: data?.data });
    } catch (error) {
        dispatch({ type: LOADED_LICENSE });
    }
  };
  export const createTeacher = (formData, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var { data } = await api.createTeacher(formData);
      setLoadingModalShow(false)
      toastAlert(t("Teacher Profile Created Successfully"), undefined, "success");
      history.push('/authentication', { step: formData.has_teaching_license_or_credential === '1'? 7:8})
      window.location.reload()
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

  export const teacherEducationAction = (formData, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var { data } = await api.teacherEducation(formData);
      setLoadingModalShow(false)
      toastAlert(t("Teacher Education Updated Successfully"), undefined, "success");
      history.push('/authentication', { step: 9})
      window.location.reload()
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

  export const teacherExperienceAction = (formData, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var { data } = await api.teacherExperience(formData);
      setLoadingModalShow(false)
      toastAlert(t("Teacher Experience Updated Successfully"), undefined, "success");
      history.push('/authentication')
      window.location.reload()
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

  export const teachingLicenseAction = (formData, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var { data } = await api.teachingLicense(formData);
      setLoadingModalShow(false)
      toastAlert(t("Teaching License Updated Successfully"), undefined, "success");
      history.push('/authentication', { step: 8})
      window.location.reload()
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

  export const createAcademy = (formData, setLoadingModalShow) => async (dispatch) => {
    try {
      var { data } = await api.createAcademy(formData);
      setLoadingModalShow(false)
      toastAlert(t("Academy Form Submitted Successfully"), undefined, "success");
      window.location.reload()
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };