import {
    FOOTER_JOB_TYPES,
    LOADING_FOOTER_JOB_TYPES,
    LOADED_FOOTER_JOB_TYPES,
  } from "../../constants/actionTypes";
  
  export default (footerJobTypes = { footerJobTypes: [], loading: false }, action) => {
    switch (action.type) {
      case FOOTER_JOB_TYPES:
        return { ...footerJobTypes, footerJobTypes: action.data, loading: false };
      case LOADING_FOOTER_JOB_TYPES:
        return { ...footerJobTypes, loading: true };
      case LOADED_FOOTER_JOB_TYPES:
        return { ...footerJobTypes, loading: false };
      default:
        return footerJobTypes;
    }
  };
  