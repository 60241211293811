import { FETCH_ABOUT, ABOUT_LOADED, LOAD_ABOUT } from '../../constants/actionTypes'

export default (aboutUs = { about: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_ABOUT:
            return { ...aboutUs, about: action.payload, loading: false };
        case LOAD_ABOUT:
            return { ...aboutUs, loading: true };
        case ABOUT_LOADED:
            return { ...aboutUs, loading: false };
        default:
            return aboutUs;
    }
}