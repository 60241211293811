import React, {useEffect} from 'react'
import './PopularSubs.css'
import english from '../../../assets/English.png'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Spinner from '../../ui/spinner/Spinner';
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom';
import JobItem from '../../jobOpenings/job_listItem/Job_Item';
import { useDispatch } from 'react-redux';
import { getJobs } from '../../../redux/actions/jobs';
import { useLocation } from 'react-router';
const PopularSubs = (props) => {
	const history = useHistory()
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const { job, loading } = useSelector(state => state.job)
    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getJobs(1, lng === "arab" ? 2 : 1, "", "", "", "", { min: 0, max: '' }, '' ))
    }, [location])
    return (
        <section  style={{minHeight: 'unset', direction: lng === 'arab'? 'rtl': 'ltr'}} className="available_jobs_section">
            <div style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='av_jobs_header'>
                <div className='title_and_breif'>
                    <h1 style={{width: '100%'}}>{t("Available Jobs")}</h1>
                </div>
                <Link to='/jobs'>{t("View All")}</Link>
            </div>                
            <div className='row jobItemsSection' style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', width: '70%', paddingTop: '10px' }}>
                {loading ?
                    <Spinner />
                    :
                job?.length === 0 ?
                    <NoDataFound />
                    :
                    job?.slice(0, 3).map((item, index) => (
                        <JobItem userDetails={props.userDetails} loading={props.loading} colorOfText='red' data={item} key={index}/>
                    ))
                }
            </div>
        </section>
    )
}

export default PopularSubs