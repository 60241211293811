/* eslint-disable */

import React, { useState, useEffect } from 'react'
import JobPost from './job_post/Job_post';
import './AvailableJobs.css'
import { useTranslation } from 'react-i18next'
import Spinner from '../../ui/spinner/Spinner';
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import SwiperCore,{ Navigation } from 'swiper';
import { Link } from 'react-router-dom';
SwiperCore.use([Navigation]);

const AvailableJobs = () => {
  const { availableJob, loading } = useSelector(state => state.homeAvailableJob)
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;

  // const availableJob = [];
  // const loading = false
  const test = useSelector(state => state)
  const { t } = useTranslation();
  const [view, setView] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    if (window.innerWidth > 1400) {
      setView(3);
    } else if (window.innerWidth > 1200 && window.innerWidth <= 1400) {
      setView(3);
    } else if (window.innerWidth > 1070 && window.innerWidth <= 1200) {
      setView(2.7);
    } else if (window.innerWidth > 900 && window.innerWidth <= 1070) {
      setView(2.3);
    } else if (window.innerWidth > 788 && window.innerWidth <= 900) {
      setView(2);
    } else if (window.innerWidth > 600 && window.innerWidth <= 788) {
      setView(1.5);
    } else if (window.innerWidth > 480 && window.innerWidth <= 600) {
      setView(1.2);
    } else if (window.innerWidth > 309 && window.innerWidth <= 480) {
      setView(1);
    } else {
      setView(1);
    }
  }, [width]);
  return (
    <section className="available_jobs_section">
      <div style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='av_jobs_header'>
          <div className='title_and_breif'>
            <h1 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Available Schools")}</h1>
            <p style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Here you can find avaiable jobs listed by the schools")}</p>
          </div>
          <Link to='/schools'>{t("View All")}</Link>
      </div>
      {loading?
        <Spinner/>:
      !loading && availableJob?.length === 0 ?
        <NoDataFound />:
        <>
        <div style={{width: '100%'}}>
          <Swiper
            slidesPerView={view}
            spaceBetween={10}
            loop={availableJob?.length > view ? true : false}
            navigation={true}
          >
            {
            availableJob?.map((item, index) => (
              <SwiperSlide key={index}><JobPost data={item}  /></SwiperSlide>
            ))
            }
          </Swiper>
        </div></>}
    </section>
  )
}

export default AvailableJobs