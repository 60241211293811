import React, { useEffect, useState } from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import SchoolList from '../ui/list/List'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSchool } from '../../redux/actions/school_Page'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
const School = () => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const history = useHistory()
  const dispatch = useDispatch()
  const { school, loading } = useSelector(state => state.school);
  const popularCities = useSelector(state => state.popular_cities.popularCities);
	const popularCitiesLoading = useSelector(state => state.popular_cities.loading);
  const search = useLocation().search;
	const cityURL = new URLSearchParams(search).get('cit');
  const pageURL = new URLSearchParams(search).get('pg');
  const [searchState, setSearchState] = useState('')
  useEffect(() => {
    if (search && !cityURL && !pageURL) {
      alert('reload')
      history.push('/schools')
    }
    if (pageURL > lastPage) {
      alert('lastPage')

      history.push('/schools')
    } else if (pageURL <= 0 && pageURL) {
      alert(pageURL)

      history.push('/schools')
    }
  }, [cityURL, pageURL])
	const currentPage = useSelector((state) => state?.school?.currentPage);
	const nextPage = useSelector((state) => state?.school?.nextPage);
	const lastPage = useSelector((state) => state?.school?.lastPage);
  useEffect(() => {
    dispatch(getSchool(lng === "arab" ? 2 : 1, searchState, {city: cityURL || '', state: ''}, pageURL || 1))
  }, [dispatch, lng, cityURL, search, pageURL, searchState])
  return (
    <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className='pages_with_header'>
      <ReusableBanner title={t("Schools") + (!cityURL? '' : !loading && school.length === 0? '' : (' ' + t("In") + ' ' + cityURL))} bacgroundClass="schoolBC" />
      <SchoolList 
        currentPage={currentPage}
        nextPage={nextPage}
        lastPage={lastPage}
        cityURL={cityURL}
        school={school}
        loading={loading}
        setSearchState={setSearchState}
        searchState={searchState}
        popularCities={popularCities}
        search="Search School" 
        type="school" 
      />
    </div>
  )
}

export default School