import React, {useEffect} from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import Details from './details/Details'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { getSingleArticle } from '../../redux/actions/article_page';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner from '../ui/spinner/Spinner';
import NoDataFound from '../ui/noDataFound/NoDataFound';
const BlogDetail = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSingleArticle(lng === 'arab'? 1:2, id))
    }, [id])
    const { singleSrticle, loading } = useSelector(state => state.singleArticle);
    return (
        <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
            {loading?
            <div style={{minHeight: '90vh', display:'flex', alignItems:'center', justifyContent: 'center'}}>
            <Spinner/></div>:
            <>
            <ReusableBanner background={singleSrticle?.image} title={singleSrticle?.title || t("Blog Details")} />
            {!loading && Object.keys(singleSrticle).length === 0?
            <NoDataFound/>:
            <Details loading={loading} details={singleSrticle}/>
        }
            </>
        }
            
        </div>
    )
}

export default BlogDetail