import React from 'react'
import './ResuableBanner.css'
import pricingBC from '../../../assets/pricingBC.jpg'
import ImgEffect from '../imgsEffect/ImgEffect'
const ReusableBanner = (props) => {
  return (
    <section className={`reusable_banner ${props.bacgroundClass}`}>
      <ImgEffect colorNeeded={props.color} />
      {!props.bacgroundClass ?
        <img src={props.background || pricingBC} alt='banner_background' /> : null
      }
      <h1 style={{ color: props.textColor }}>{props.title}</h1>
    </section>
  )
}

export default ReusableBanner