import { MAXIMUM_SALARY, LOADING_MAXIMUM_SALARY, LOADED_MAXIMUM_SALARY } from '../../constants/actionTypes'

export default (maxSalary = { maxSalary: {}, loading: false }, action) => {
    switch (action.type) {
        case MAXIMUM_SALARY:
            return { ...maxSalary, maxSalary: action.data, loading: false };
        case LOADING_MAXIMUM_SALARY:
            return { ...maxSalary, loading: true };
        case LOADED_MAXIMUM_SALARY:
            return { ...maxSalary, loading: false };
        default:
            return maxSalary;
    }
}