import {
  POPULAR_JOB_TITLES,
  LOADING_POPULAR_JOB_TITLES,
  LOADED_POPULAR_JOB_TITLES,
} from "../../constants/actionTypes";

export default (popularJobs = { popularJobs: [], loading: false }, action) => {
  switch (action.type) {
    case POPULAR_JOB_TITLES:
      return { ...popularJobs, popularJobs: action.data, loading: false };
    case LOADING_POPULAR_JOB_TITLES:
      return { ...popularJobs, loading: true };
    case LOADED_POPULAR_JOB_TITLES:
      return { ...popularJobs, loading: false };
    default:
      return popularJobs;
  }
};
