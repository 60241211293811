import React, { useEffect, useState } from 'react'
import './JobRightSection.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import shareIcon from '../../../assets/shareIcon.png'
import Spinner from '../../ui/spinner/Spinner'
import { useDispatch } from 'react-redux'
import { saveJob, unSaveJob, applyForJob } from '../../../redux/actions/jobs'
import JobModal from '../jobActionModal/JobModal'
import CopyToClipboard from "react-copy-to-clipboard";
import { toastAlert } from '../../../layouts/nav/toastAlert'
import { Modal } from 'react-bootstrap'

const JobRightSection = ({jobDetails, setJobDetails}) => {
    const { t } = useTranslation();
    // const { job, loading } = useSelector(state => state.job)
    const location = useLocation();
    const [showCopyCard, setshowCopyCard] = useState(false)
    const userDetails = useSelector((state) => state?.userDetails?.authData);
    const userDetailsLoading = useSelector((state) => state?.userDetails?.loading);
    const [actionLoading, setActionLoading] = useState(false)
    const [actionModal, setActionModal] = useState(false)
    const [dataTransfer, setDataTransfer] = useState({message: '', status: 0})

    const dispatch = useDispatch()
    const job = {data: [1]};
    const loading = false
    const search = useLocation().search;
    const id = new URLSearchParams(search).get('id');
    const index = job?.data?.map(object => object?.id).indexOf(Number(id))
    const applyForAjob = (jobID) => {
        if (jobDetails?.apply_by_website === '1') {
            window.location.open(jobDetails?.job_application_link)
        } else if (!localStorage.getItem("sess")) {
            setDataTransfer({...dataTransfer, message: 'Please login first before applying for this job', status: 1});
            setActionModal(true)
        } else if (localStorage.getItem("sess") && !userDetails?.type) {
            setDataTransfer({...dataTransfer, message: 'Please complete your registeration process before applying for this job', status: 2});
            setActionModal(true)
        } else if (userDetails?.type !== "T") {
            setDataTransfer({...dataTransfer, message: "Hi sir, As an academy you can't apply for a job", status: 3});
            setActionModal(true)
        } else if (userDetails?.type === "T") {
            if (userDetails?.have_education === '0' && userDetails?.have_experience === '0') {
                setDataTransfer({...dataTransfer, message: "You have some missing informarion, Please add education and experience information before applying for this job", status: 4});
                setActionModal(true)
            } else if (userDetails?.have_education === '0') {
                setDataTransfer({...dataTransfer, message: "Please add some education information before applying for this job", status: 4});
                setActionModal(true)
            } else if (userDetails?.have_experience === '0') {
                setDataTransfer({...dataTransfer, message: "Please add some experience information before applying for this job", status: 4});
                setActionModal(true)
            } else {
                setActionLoading(true)
                dispatch(applyForJob(jobID, setActionLoading, setActionModal, setDataTransfer, setJobDetails))
            }
        } else {
            setDataTransfer({...dataTransfer, message: "Somthing went wrong!", status: 5});
            setActionModal(true)
        }
    }
    const saveJobInner = (jobID) => {
        if (!localStorage.getItem("sess")) {
            setDataTransfer({...dataTransfer, message: 'Please login first before saving this job', status: 1});
            setActionModal(true)
        } else if (localStorage.getItem("sess") && !userDetails?.type) {
            setDataTransfer({...dataTransfer, message: 'Please complete your registeration process before saving this job', status: 2});
            setActionModal(true)
        } else if (userDetails?.type !== "T") {
            setDataTransfer({...dataTransfer, message: "Hi sir, As an academy you can't save a job", status: 3});
            setActionModal(true)
        } else if (userDetails?.type === "T") {
            if (jobDetails?.TeacherSavedJob == '1') {
                setActionLoading(true)
                dispatch(unSaveJob(jobID, setActionLoading, setJobDetails))
            } else {
                setActionLoading(true)
                dispatch(saveJob(jobID, setActionLoading, setJobDetails))
            }
        } else {
            setDataTransfer({...dataTransfer, message: "Somthing went wrong!", status: 5});
            setActionModal(true)
        }
    }
    return (!loading && job?.data) ? (
        <div className='JobRightSection'>
            {
            !jobDetails?.job_status_id?
            <div className='job_filter_box'>
                <label>{t("This job is a draft")}</label>
            </div>:
            jobDetails?.job_status_id == '2'?
            <div className='job_filter_box'>
                <label>{t("This job is cuerrently pending")}</label>
            </div>:
            jobDetails?.job_status_id == '3'?
            <div className='job_filter_box'>
                <label>{t("This job is cuerrently colsed")}</label>
            </div>:
            jobDetails?.job_status_id == '4'?
            <div className='job_filter_box'>
                <label>{t("This job is archived")}</label>
            </div>:
            jobDetails?.job_status_id == '5'?
            <div className='job_filter_box'>
                <label>{t("This job is rejected")}</label>
            </div>:
            localStorage.getItem("sess")?
            userDetailsLoading && !Object.keys(userDetails).length?
            <Spinner/>:
            !userDetailsLoading && Object.keys(userDetails).length?
            userDetails.type === "A"?
            null:
            <div className='job_filter_box'>
                {actionLoading?
                <Spinner/>:
                <div className="jobDetailsBtnsWrapper">
                    <button onClick={() => saveJobInner(jobDetails?.id)} className="uk-button uk-button-default jobDetailsBtn1" type='button'>
                    {jobDetails?.TeacherSavedJob?
                        t("Saved"):<><i className='bx bx-heart'></i>{t("Save Job")}</>
                    } 
                    </button>
                    {jobDetails?.TeacherAppliedToJob == 1?
                    <button disabled className="uk-button uk-button-default jobDetailsBtn2" type='button'>
                        {t("Applied")}
                    </button>:
                     <button onClick={() => applyForAjob(jobDetails?.id)} className="uk-button uk-button-default jobDetailsBtn2" type='button'>
                        {t("Apply Now")}
                    </button>
                    }
                </div>
                } 
            </div>:
            null:
            <div className='job_filter_box'>
                <div className="jobDetailsBtnsWrapper">
                    <button onClick={() => saveJobInner(jobDetails?.id)} className="uk-button uk-button-default jobDetailsBtn1" type='button'>
                        <i className='bx bx-heart'></i>
                        {t("Save Job")}
                    </button>
                     <button onClick={() => applyForAjob(jobDetails?.id)} className="uk-button uk-button-default jobDetailsBtn2" type='button'>
                        {t("Apply Now")}
                    </button>
                </div>
            </div>
            }

            <div className='job_filter_box jobSetails'>
                <h6>{t("Job Overview")}</h6>
                {jobDetails?.start_date?
                <label><span className='span'>{t("Published on")}: </span>{ jobDetails?.start_date}</label>:null
                }
                
                {jobDetails?.number_of_vacancies?
                <label><span className='span'>{t("Vacancy")}: </span>{jobDetails?.number_of_vacancies}</label>:null
                }
                {jobDetails?.minimum_teaching_experience?
                <label><span className='span'>{t("Experience")}: </span>{jobDetails?.minimum_teaching_experience + ' ' + t('Years')}</label>:null
                }
                {jobDetails?.location?
                <label><span className='span'>{t("Job Location")}: </span>{jobDetails?.location?.city || t('N/A')}</label>:null
                }
                {jobDetails?.subjects.length?
                <label><span className='span'>{t("Subjects")}: </span>{jobDetails?.subjects?.map((item, i) => item?.name ).join(t(", "))}</label>:null
                }
                {jobDetails?.publish_salary_information === '1'?
                jobDetails?.minimum_salary?
                    <label><span className='span'>{t("Salary")}: </span>
                    {
                    jobDetails?.publish_salary_information === '1'?
                    jobDetails?.minimum_salary && jobDetails?.maximum_salary?
                    t('SAR') + " " + jobDetails?.minimum_salary + ' - ' + jobDetails?.maximum_salary:
                    !jobDetails?.maximum_salary && jobDetails?.minimum_salary?
                    t('SAR') + " " + jobDetails?.maximum_salary:
                    t('SAR') + " " + jobDetails?.minimum_salary:
                    ''
                    }
                    </label>:null:null}
                {jobDetails?.grades.length?
                <label><span className='span'>{t("Levels")}: </span>{jobDetails?.grades?.map((item, i) => item?.name ).join(t(', '))}</label>:null
                }
                {(jobDetails?.contract_length_years && jobDetails?.contract_length_years != '0') || (jobDetails?.contract_length_months && jobDetails?.contract_length_months != '0')?
                <label><span className='span'>{t("Contract Length")}: </span>{ (jobDetails?.contract_length_years? jobDetails?.contract_length_years + ' ' + t("Years"):'') + (jobDetails?.contract_length_years && jobDetails?.contract_length_months?t(" and "):'') + (jobDetails?.contract_length_months? jobDetails?.contract_length_months + ' ' + t("Months"):'')}</label>:null
                }                {jobDetails?.close_date?
                <label><span className='span'>{t("Application Deadline")}: </span>{ jobDetails?.close_date}</label>:null
                }
            </div>
            <div onClick={() => setshowCopyCard(true)} className='share_wrapper'>
                <label>{t("Share")}</label>
                <img src={shareIcon} alt='share_icon'/>
            </div>
            <JobModal
                show={actionModal}
                dataTransfer={dataTransfer}
                onHide={() => setActionModal(false)}
            />
            <ShareAlertModel
                show={showCopyCard}
                onHide={() => {
                    setshowCopyCard(false);
                }}
            />
            {/* <div className='job_filter_box jobSetails'>
                <h6>{t("Job Location")}</h6>
                <div className='mapEffect' />
            </div> */}
        </div>
    ) : (
        null
    )
}

export default JobRightSection

function ShareAlertModel(props) {
  const { t } = useTranslation();
  const handleRegularShare = () => {
        toastAlert("Copied", "Url has been copied to clipboard", "success");
  };
  const handleShareFromSocial = async (type) => {
      if (type==='whatsapp') {
        window.open(`https://api.whatsapp.com/send?phone=&text=${window.location.href}`);
      } else if (type==='twitter') {
        window.open(`http://twitter.com/share?text=CVSAjob&url=${window.location.href}&hashtags=CV,SA`);
      } else {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
      }
  };
  return (
    <Modal
        {...props}
        size="md"
        centered
        dialogClassName="popUpLoading"
    >
      <Modal.Body>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <h3 style={{ textAlign: 'center', color: 'white' }}>{t("Share")}</h3>
            <div className="social-media">
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#25d366'}} className="social-media-btn" onClick={() => handleShareFromSocial('whatsapp')}>
                      <i  className="fab fa-whatsapp"></i>
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("WhatsApp")}</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#1da1f2'}} className="social-media-btn" onClick={() => handleShareFromSocial('twitter')}>
                      <i  className="fab fa-twitter"></i>
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("Twitter")}</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                    <button style={{backgroundColor:'#1877f2'}} className="social-media-btn" onClick={() => handleShareFromSocial('facbook')}>
                      <i className="fab fa-facebook-f"></i>
                    </button>
                    <label style={{color:'white'}} className='label_of_title'>{t("Facebook")}</label>
                </div>
              <CopyToClipboard onCopy={()=> handleRegularShare()} text={window.location.href} >
                <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                <button className='social-media-btn' style={{ backgroundColor: "white", display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', height: '60px', width: '60px', borderRadius: '50%' }}>
                  <img src={shareIcon} alt="donate_btn_icon_banner" style={{ height: '30px', width: '30px' }} />
                </button>
                <label style={{color:'white'}} className='label_of_title'>{t("Copy link")}</label>
                </div>
              </CopyToClipboard>
            </div>


          </div>
      </Modal.Body>
    </Modal>
  );
}
