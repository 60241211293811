import { SUBJECTS, LOADING_SUBJECTS, LOADED_SUBJECTS } from "../../constants/actionTypes";

export default (subjects = { subjects: [], loading: false }, action) => {
    switch (action.type) {
        case SUBJECTS:
            return { ...subjects, subjects: action.data, loading: false };
        case LOADING_SUBJECTS:
            return { ...subjects, loading: true };
        case LOADED_SUBJECTS:
            return { ...subjects, loading: false };
        default:
            return subjects;
    }
}