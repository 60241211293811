import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { INCREASE_VIEWS, LOADING_INCREASE_VIEWS, LOADED_INCREASE_VIEWS } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getIncreaseViews = (job_id) => async(dispatch) => {
    dispatch({ type: LOADING_INCREASE_VIEWS });
    try {
        var { data } = await api.increaseViews(job_id);
        await dispatch({ type: INCREASE_VIEWS, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_INCREASE_VIEWS });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}