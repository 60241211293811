import { FETCH_SCHOOL, SCHOOL_LOADED, LOAD_SCHOOL } from '../constants/actionTypes'
import * as api from '../../api'

export const getSchool = (lang, search, filters, pageNumber) => async (dispatch) => {
    dispatch({ type: LOAD_SCHOOL });
    try {
        const { data } = await api.getSchools(lang, search, filters, pageNumber);
        dispatch({ type: FETCH_SCHOOL, data: data.data })
    } catch (error) {
        dispatch({ type: SCHOOL_LOADED })
    }
}