import { FETCH_JOB_LEVEL, JOB_LEVEL_LOADED, LOAD_JOB_LEVEL, FETCH_BANNER, BANNER_LOADED, LOAD_BANNER, FETCH_SUBJECT, SUBJECT_LOADED, LOAD_SUBJECT, FETCH_ARTICLE, ARTICLE_LOADED, LOAD_ARTICLE, FETCH_AVAILABLE_JOB, AVAILABLE_JOB_LOADED, LOAD_AVAILABLE_JOB } from '../constants/actionTypes'
import * as api from '../../api'

export const getArticles = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_ARTICLE });
    try {
        const { data } = await api.getHPArticles(lang);
        dispatch({ type: FETCH_ARTICLE, payload: data })
    } catch (error) {
        dispatch({ type: ARTICLE_LOADED })
    }
}

export const getAvailableJobs = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_AVAILABLE_JOB });
    try {
        const { data } = await api.getAvailableJobs(lang);
        dispatch({ type: FETCH_AVAILABLE_JOB, payload: data.data })
    } catch (error) {
        dispatch({ type: AVAILABLE_JOB_LOADED })
    }
}

export const getSubjects = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_SUBJECT });
    try {
        const { data } = await api.getHPSubjects(lang);
        dispatch({ type: FETCH_SUBJECT, payload: data })
    } catch (error) {
        dispatch({ type: SUBJECT_LOADED })
    }
}

export const getBanners = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_BANNER });
    try {
        const { data } = await api.getHPBanner(lang);
        dispatch({ type: FETCH_BANNER, payload: data })
    } catch (error) {
        dispatch({ type: BANNER_LOADED })
    }
}

export const getJobLevel = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_JOB_LEVEL });
    try {
        const { data } = await api.getHPJobLevel(lang);
        dispatch({ type: FETCH_JOB_LEVEL, payload: data })
    } catch (error) {
        dispatch({ type: JOB_LEVEL_LOADED })
    }
}
