import React from 'react'
import './ForSchools.css'
import uImg from '../../../assets/defaultData/more2.png'
import ZImg from '../../../assets/defaultData/more1.png'
import checkMark from '../../../assets/checked.png'
import num1 from '../../../assets/1.svg'
import num2 from '../../../assets/2.svg'
import num3 from '../../../assets/3.svg'
import icon1 from '../../../assets/icon1.svg'
import icon2 from '../../../assets/icon2.svg'
import icon3 from '../../../assets/icon3.svg'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const ForSchools = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <section style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='get_hired'>
            <div className='upper_section'>
                <h1 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Get hired in 3 simple steps.")}</h1>
                <div className='get_hired_btn_wrapper'>
                    <p style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Don’t miss the chance")}</p>
                    <Link to={'/authentication'}>
                        {props.userDetails?.type === 'T'? t("Dashboard"):t("Create Account Now")}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.522" height="16.522" viewBox="0 0 17.923 17.923">
                            <g  id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(-6.5 -6.5)">
                                <path className='get_hirde_banner_btn_arrow' id="Path_82" data-name="Path 82" d="M18,7.5V23.423" transform="translate(-2.538)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <path className='get_hirde_banner_btn_arrow' id="Path_83" data-name="Path 83" d="M7.5,18H23.423" transform="translate(0 -2.538)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                        </svg>
                    </Link>
                </div>
            </div>
            <div className='steps_section'>
                <div className='big_wrapper'>
                <div className='number_wrapper'>
                    <img src={icon1} alt='icon1' className='icon_1'/>
                    <div className='step_text_wrapper'>
                        <label>{t("Register")}</label>
                        <p>{t("Create an account with us, fill all your information.")}</p>
                    </div>
                </div>
                <img src={num1} alt='number1' className='number_1'/>
                </div>
                
                <div className='big_wrapper'>
                <div className='number_wrapper'>
                    <img src={icon2} alt='icon1' className='icon_1'/>
                    <div className='step_text_wrapper'>
                        <label>{t("Apply")}</label>
                        <p>{t("Apply for available jobs listed from different schools all around Saudi Arabia")}</p>
                    </div>
                </div>
                <img src={num2} alt='number1' className='number_1'/>
                </div>
                <div className='big_wrapper'>
                <div className='number_wrapper'>
                    <img src={icon3} alt='icon1' className='icon_1'/>
                    <div className='step_text_wrapper'>
                        <label>{t("Get Hired")}</label>
                        <p>{t("Multiple schools will be able to view your application")}</p>
                    </div>
                </div>
                <img src={num3} alt='number1' className='number_1'/>
                </div>
            </div>
        </section>
    )
}

export default ForSchools