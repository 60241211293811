import { FETCH_ABOUT, ABOUT_LOADED, LOAD_ABOUT } from '../constants/actionTypes'
import * as api from '../../api'
// import { toastifyMessage } from "../handlers/toastifyMessage";

export const getAboutUS = (lang) => async (dispatch) => {
    dispatch({ type: LOAD_ABOUT });
    try {
        const { data } = await api.getAbout(lang);
        dispatch({ type: FETCH_ABOUT, payload: data })
    } catch (error) {
        //toastifyMessage("Something went wrong...!", undefined, "error");
        dispatch({ type: ABOUT_LOADED })
    }
}