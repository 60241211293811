import { FETCH_FAQ, FAQ_LOADED, LOAD_FAQ } from '../../constants/actionTypes'

export default (faq = { faq: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_FAQ:
            return { ...faq, faq: action.payload, loading: false };
        case LOAD_FAQ:
            return { ...faq, loading: true };
        case FAQ_LOADED:
            return { ...faq, loading: false };
        default:
            return faq;
    }
}