import React from 'react'
import { useTranslation } from 'react-i18next'

const NoDataFound = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{ textAlign: 'center', padding: "10%" }}>{props.apiMsg || t("No Available Data")}</div>
    )
}

export default NoDataFound