import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import { t } from "i18next";
import loginImg from "../../../../assets/authBC.png";
import skip from "../../../../assets/skip.png";
import skip_left from "../../../../assets/skip_right.png";
import { useHistory } from "react-router-dom";
import StepsOne from "./teacherSteps/StepsOne";
import {
  createTeacher,
  teacherEducationAction,
  teacherExperienceAction,
  teachingLicenseAction,
} from "../../../../redux/actions/registeration";
import { toastAlert } from "../../../../layouts/nav/toastAlert";
import StepsTwo from "./teacherSteps/StepsTwo";
import StepsThree from "./teacherSteps/StepsThree";
import StepsFour from "./teacherSteps/StepsFour";
import { Link } from "react-router-dom";

const TeacherRegistration = ({
  setLoadingModalShow,
  setViewOuter,
  setAuthStatus,
  authStatus,
}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [step, setStep] = useState(
    authStatus === 7 ? 1 : authStatus === 8 ? 2 : authStatus === 9 ? 3 : 0
  );
  const [profileState, setProfileState] = useState("");
  const [resume, setResume] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setStep(
      authStatus === 7 ? 1 : authStatus === 8 ? 2 : authStatus === 9 ? 3 : 0
    );
  }, [authStatus]);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const [teacherRegStep1, setTeacherRegStep1] = useState({
    first_name: "",
    last_name: "",
    gender_id: "1",
    date_of_birth: `${formatDate(
      new Date().setFullYear(new Date().getFullYear() - 18)
    )}`,
    contact_number: "",
    country: "",
    state: "",
    languages: [],
    citizenships: [],
    avatar: "",
    resume: "",
    job_title_id: "1",
    has_esl_teaching_certificate: "0",
    has_teaching_license_or_credential: "1",
    allow_contact_directly: "0",
    interested_online_teaching: "0",
    allow_share_docs: "0",
    city: "",
  });

  const [teacherEducation, setTeacherEducation] = useState([
    {
      degree_level_id: " 1",
      title: "",
      college: "",
      year_of_graduation: new Date(),
      // start_date: new Date(),
      // end_date: new Date()
    },
  ]);
  const addMoreEducationFeilds = (e, index) => {
    let newfield = {
      degree_level_id: "1",
      title: "",
      college: "",
      year_of_graduation: new Date(),
      // end_date: new Date()
    };
    setTeacherEducation([...teacherEducation, newfield]);
  };
  const removeEducationFeild = (index) => {
    let data = [...teacherEducation];
    data.splice(index, 1);
    setTeacherEducation(data);
  };
  const educationChange = (e, index) => {
    let data = [...teacherEducation];
    data[index][e.target.name] = e.target.value;
    setTeacherEducation(data);
  };
  const [teacherExperience, setTeacherExperience] = useState([
    {
      experience_type: "Teaching",
      title: "",
      school: "",
      country: "",
      curriculum_id: "1",
      start_date: new Date(),
      end_date: new Date(),
      still_work_there: "0",
      grades: [],
      subjects: [],
    },
  ]);
  const addMoreExpFeilds = (e, index) => {
    let newfield = {
      experience_type: "Teaching",
      title: "",
      school: "",
      country: "",
      curriculum_id: "1",
      start_date: new Date(),
      end_date: new Date(),
      still_work_there: "0",
      grades: [],
      subjects: [],
    };
    setTeacherExperience([...teacherExperience, newfield]);
  };
  const removeExpFeild = (index) => {
    let data = [...teacherExperience];
    data.splice(index, 1);
    setTeacherExperience(data);
  };
  const expChange = (e, index) => {
    let data = [...teacherExperience];
    data[index][e.target.name] = e.target.value;
    setTeacherExperience(data);
  };
  const [teachingLicense, setTeachingLicense] = useState([
    {
      place_of_issuance: "",
      teaching_license_type_id: "1",
      country: "",
      state: "",
      valid_from: new Date(),
      valid_to: new Date(),
      has_expiration_date: "1",
      grades: [],
      subjects: [],
    },
  ]);
  const addMoreTeachingLicenseFeilds = (e, index) => {
    let newfield = {
      place_of_issuance: "",
      teaching_license_type_id: "1",
      country: "",
      state: "",
      valid_from: new Date(),
      valid_to: new Date(),
      has_expiration_date: "1",
      grades: [],
      subjects: [],
    };
    setTeachingLicense([...teachingLicense, newfield]);
  };
  const removeTeachingLicenseFeild = (index) => {
    let data = [...teachingLicense];
    data.splice(index, 1);
    setTeachingLicense(data);
  };
  const teachingLicenseChange = (e, index) => {
    let data = [...teachingLicense];
    data[index][e.target.name] = e.target.value;
    setTeachingLicense(data);
  };

  const submitFirstStep = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    let fullData = {
      ...teacherRegStep1,
      avatar: profileState,
      resume: resume,
      date_of_birth: formatDate(teacherRegStep1.date_of_birth),
    };
    if (!fullData.first_name) {
      errorArr.push(t("Please enter your first name"));
    }
    if (!fullData.last_name) {
      errorArr.push(t("Please enter your last name"));
    }
    if (!fullData.contact_number) {
      errorArr.push(t("Please enter your number"));
    }
    if (!fullData.city || !fullData.state || !fullData.country) {
      errorArr.push(t("Please tell us where do you live"));
    }
    if (!fullData.languages?.length) {
      errorArr.push(t("Please add your fluent languages"));
    }
    if (!fullData.citizenships?.length) {
      errorArr.push(t("Please add your citizenship"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(createTeacher(fullData, setLoadingModalShow, history));
  };

  const submitEducation = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    if (teacherEducation.length === 0) {
      errorArr.push(t("Please add an education degree"));
    }
    let newEducation = [];
    for (let i = 0; i < teacherEducation.length; i++) {
      newEducation.push(teacherEducation[i]);
      for (const item in teacherEducation[i]) {
        if (!teacherEducation[i][item]) {
          if (item === "degree_level_id") {
            errorArr.push(
              t("Education Form ") +
                `(${i + 1}):` +
                t(" Degree level is required")
            );
          } else if (item === "title") {
            errorArr.push(
              t("Education Form ") +
                `(${i + 1}):` +
                t(" Education field is required")
            );
          } else if (item === "year_of_graduation") {
            errorArr.push(
              t("Education Form ") +
                `(${i + 1}):` +
                t("year of graduation is required")
            );
          } else {
            errorArr.push(
              t("Education Form ") +
                `(${i + 1}):` +
                t(" Place of issurance is required")
            );
          }
        } else {
          // if (item === "year_of_graduation") {
          //   if (new Date(teacherEducation[i]["year_of_graduation"])) {
          //     errorArr.push(t("Education Form ")+`(${i+1}):`+t("year of graduation is required"))
          //   }
          // } else if (item === "end_date") {
          //   if (new Date(teacherEducation[i]["start_date"]).getTime() > new Date(teacherEducation[i]["end_date"]).getTime()) {
          //     errorArr.push(t("Education Form ")+`(${i+1}):`+t(" start date can't come after the end date"))
          //   }
          // }
        }
        if (item === "year_of_graduation") {
          let date = new Date(teacherEducation[i][item]).getFullYear();
          newEducation[i][item] = date;
        }
      }
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(
      teacherEducationAction(newEducation, setLoadingModalShow, history)
    );
  };

  const submitExp = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    if (teacherExperience.length === 0) {
      errorArr.push(t("Please add an Experience"));
    }
    let newEducation = [];
    for (let i = 0; i < teacherExperience.length; i++) {
      newEducation.push(teacherExperience[i]);
      for (const item in teacherExperience[i]) {
        if (!teacherExperience[i][item]) {
          if (item === "title") {
            errorArr.push(
              t("Experience Form ") +
                `(${i + 1}):` +
                t(" Role Title is required")
            );
          } else if (item === "experience_type") {
            errorArr.push(
              t("Experience Form ") +
                `(${i + 1}):` +
                t(" Experience Type is required")
            );
          } else if (item === "school") {
            errorArr.push(
              t("Experience Form ") +
                `(${i + 1}):` +
                t(" Place of Issurance is required")
            );
          } else if (item === "country") {
            errorArr.push(
              t("Experience Form ") + `(${i + 1}):` + t(" Country is required")
            );
          } else if (item === "curriculum_id") {
            errorArr.push(
              t("Experience Form ") +
                `(${i + 1}):` +
                t(" curriculum you tought is required")
            );
          }
        } else {
          if (item === "grades" && teacherExperience[i][item].length === 0) {
            errorArr.push(
              t("Experience Form ") +
                `(${i + 1}):` +
                t(" Please add grades level")
            );
          }
          if (item === "subjects" && teacherExperience[i][item].length === 0) {
            errorArr.push(
              t("Experience Form ") + `(${i + 1}):` + t(" Please add subjects")
            );
          }
          if (item === "start_date") {
            if (
              new Date(teacherExperience[i]["start_date"]).getTime() ===
                new Date(teacherExperience[i]["end_date"]).getTime() &&
              teacherExperience[i]["still_work_there"] == "0"
            ) {
              errorArr.push(
                t("Experience Form ") +
                  `(${i + 1}):` +
                  t(" start date and end date can't be the same")
              );
            }
          } else if (item === "end_date") {
            if (
              new Date(teacherExperience[i]["start_date"]).getTime() >
                new Date(teacherExperience[i]["end_date"]).getTime() &&
              teacherExperience[i]["still_work_there"] == "0"
            ) {
              errorArr.push(
                t("Experience Form ") +
                  `(${i + 1}):` +
                  t(" start date can't come after the end date")
              );
            }
          }
        }
        if (item === "start_date" || item === "end_date") {
          let date;
          if (item === "end_date") {
            if (teacherExperience[i]["still_work_there"] == "1") {
              date = "";
            } else {
              date = formatDate(teacherExperience[i][item]);
            }
          } else {
            date = formatDate(teacherExperience[i][item]);
          }
          newEducation[i][item] = date;
        }
      }
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(
      teacherExperienceAction(newEducation, setLoadingModalShow, history)
    );
  };

  const submitTeachingLicense = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    if (teachingLicense.length === 0) {
      errorArr.push(t("Please add an Teaching License"));
    }
    let newEducation = [];
    for (let i = 0; i < teachingLicense.length; i++) {
      newEducation.push(teachingLicense[i]);
      for (const item in teachingLicense[i]) {
        if (!teachingLicense[i][item]) {
          if (item === "teaching_license_type_id") {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" License type is required")
            );
          } else if (item === "place_of_issuance") {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" Place of Issurance is required")
            );
          } else if (item === "country") {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" Country is required")
            );
          } else if (item === "curriculum_id") {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" curriculum you tought is required")
            );
          }
        } else {
          if (item === "grades" && teachingLicense[i][item].length === 0) {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" Please add grades level")
            );
          }
          if (item === "subjects" && teachingLicense[i][item].length === 0) {
            errorArr.push(
              t("Teaching License Form ") +
                `(${i + 1}):` +
                t(" Please add subjects")
            );
          }
          if (item === "valid_from") {
            if (
              new Date(teachingLicense[i]["valid_from"]).getTime() ===
                new Date(teachingLicense[i]["valid_to"]).getTime() &&
              teachingLicense[i]["has_expiration_date"] == "1"
            ) {
              errorArr.push(
                t("Teaching License Form ") +
                  `(${i + 1}):` +
                  t(" start date and end date can't be the same")
              );
            }
          } else if (item === "valid_to") {
            if (
              new Date(teachingLicense[i]["valid_from"]).getTime() >
                new Date(teachingLicense[i]["valid_to"]).getTime() &&
              teachingLicense[i]["has_expiration_date"] == "1"
            ) {
              errorArr.push(
                t("Teaching License Form ") +
                  `(${i + 1}):` +
                  t(" start date can't come after the end date")
              );
            }
          }
        }
        if (item === "valid_from" || item === "valid_to") {
          let date;
          if (item === "valid_to") {
            if (teachingLicense[i]["has_expiration_date"] == "0") {
              date = "";
            } else {
              date = formatDate(teachingLicense[i][item]);
            }
          } else {
            date = formatDate(teachingLicense[i][item]);
          }
          newEducation[i][item] = date;
        }
      }
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    dispatch(teachingLicenseAction(newEducation, setLoadingModalShow, history));
  };
  const normalSkip = (skipTo) => {
    history.push("/authentication", { step: skipTo });
    window.location.reload();
  };
  return (
    <>
      <div className="teacher_reg">
        <div className="login_page">
          <div
            className="login_card_wrapper"
            style={{ direction: lng === "arab" ? "rtl" : "ltr" }}
          >
            <div className="teacher_reg_steps pb-5">
              <div className="res-circle">
                <div
                  className="circle-txt"
                >
                  1
                </div>
              </div>
              <div className={"stepBar_active"} />
              <div className="res-circle">
                <div className="circle-txt">2</div>
              </div>
              {teacherRegStep1.has_teaching_license_or_credential == 1 ? (
                <>
                  <div
                    className={
                      step === 1 || step === 2 || step === 3
                        ? "stepBar_active"
                        : "stepBar"
                    }
                  />
                  <div className="res-circle">
                    <div className="circle-txt">3</div>
                  </div>
                  <div
                    className={
                      step === 2 || step === 3 ? "stepBar_active" : "stepBar"
                    }
                  />
                  <div className="res-circle">
                    <div className="circle-txt">4</div>
                  </div>
                  <div className={step === 3 ? "stepBar_active" : "stepBar"} />
                </>
              ) : (
                <>
                  <div
                    className={
                      step === 2 || step === 3 ? "stepBar_active" : "stepBar"
                    }
                  />
                  <div className="res-circle">
                    <div className="circle-txt">3</div>
                  </div>
                  <div className={step === 3 ? "stepBar_active" : "stepBar"} />
                </>
              )}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 className="page_title">
                {t(
                  step === 0
                    ? "General Information"
                    : step === 1
                    ? "Teaching License Information"
                    : step === 2
                    ? "Education Information"
                    : "Experience Information"
                )}
              </h3>
              {step === 0 ? null : step === 3 ? (
                <Link
                  className="skip_link"
                  onClick={() => setAuthStatus(5)}
                  to={"#"}
                >
                  {t("Go to Dashboard")}
                </Link>
              ) : (
                <button
                  className="skip_link"
                  onClick={() =>
                    normalSkip(step === 1 ? 8 : step === 2 ? 9 : null)
                  }
                  to={"#"}
                >
                  {t("Skip")}
                </button>
              )}
            </div>
            <div className="cardd overflow-hidden">
              <div
                style={{ padding: "20px" }}
                className="card-body innerCardBody_teacher"
              >
                {step === 0 ? (
                  <StepsOne
                    submitFirstStep={submitFirstStep}
                    resume={resume}
                    setResume={setResume}
                    profileState={profileState}
                    setProfileState={setProfileState}
                    setViewOuter={setViewOuter}
                    setLoadingModalShow={setLoadingModalShow}
                    setTeacherRegStep1={setTeacherRegStep1}
                    teacherRegStep1={teacherRegStep1}
                    setStep={setStep}
                  />
                ) : step === 1 ? (
                  <StepsTwo
                    submitTeachingLicense={submitTeachingLicense}
                    setLoadingModalShow={setLoadingModalShow}
                    teachingLicense={teachingLicense}
                    addMoreTeachingLicenseFeilds={addMoreTeachingLicenseFeilds}
                    removeTeachingLicenseFeild={removeTeachingLicenseFeild}
                    teachingLicenseChange={teachingLicenseChange}
                  />
                ) : step === 2 ? (
                  <StepsThree
                    submitEducation={submitEducation}
                    setLoadingModalShow={setLoadingModalShow}
                    teacherEducation={teacherEducation}
                    addMoreEducationFeilds={addMoreEducationFeilds}
                    removeEducationFeild={removeEducationFeild}
                    educationChange={educationChange}
                  />
                ) : (
                  <StepsFour
                    submitExp={submitExp}
                    setLoadingModalShow={setLoadingModalShow}
                    teacherExperience={teacherExperience}
                    addMoreExpFeilds={addMoreExpFeilds}
                    removeExpFeild={removeExpFeild}
                    expChange={expChange}
                  />
                )}
              </div>
            </div>
          </div>
          {/* <div className="teacher_reg_steps">
            <div className={'stepBar_active'}/>
            {teacherRegStep1.has_teaching_license_or_credential == 1?
            <div className={step === 1 || step === 2 || step === 3? 'stepBar_active':'stepBar'}/>:null
            }
            <div className={step === 2 || step === 3? 'stepBar_active':'stepBar'}/>
            <div className={step === 3? 'stepBar_active':'stepBar'}/>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TeacherRegistration;
