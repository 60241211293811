import React, { useState, useEffect } from 'react'
import './Banner.css'
import homeDefaultBanner from '../../../assets/Banner.png'
import newBanner from '../../../assets/banner.svg'
import arrow from '../../../assets/arrow.svg'
import circleShadow from '../../../assets/shadowEffect.svg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Spinner from '../../ui/spinner/Spinner'
import NoDataFound from '../../ui/noDataFound/NoDataFound'
import { Link } from 'react-router-dom'

const Banner = () => {
    const userDetails = useSelector((state) => state?.userDetails?.authData);
    const loading = useSelector((state) => state?.userDetails?.loading);
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <section style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='home_banner'>
            <div className='left_content'>
                <h1 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Your teaching job is right here!")}</h1>
                <p style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("We are here to help you get it")}</p>
                <Link to={userDetails?.type === 'A'? '/authentication':'/jobs'}>
                    {t("Get Started!")}
                    <svg style={{transform: lng === 'arab'? 'rotate(180deg)':null}} xmlns="http://www.w3.org/2000/svg" width="16.522" height="16.522" viewBox="0 0 16.522 16.522">
                        <path className='banner_btn_arrow' id="Icon_ionic-ios-arrow-dropup-circle" data-name="Icon ionic-ios-arrow-dropup-circle" d="M8.261,16.522A8.261,8.261,0,1,0,0,8.261,8.26,8.26,0,0,0,8.261,16.522Zm0-9.711L5.04,10.064A.767.767,0,0,1,3.956,8.98l3.8-3.789a.766.766,0,0,1,1.056-.024L12.558,8.9a.765.765,0,1,1-1.08,1.084Z" transform="translate(16.522) rotate(90)" fill="#fff"/>
                    </svg>
                </Link>
                <div style={{left: lng === 'arab'? 'auto':null, right: lng === 'arab'? '-170px':null}} className='circle_shadow'>
                    <img src={circleShadow} alt='circle_shadow'/>
                </div>
            </div>
            <div className='right_content'>
                <img src={newBanner} alt="new_banner" className='new_banner'/>
            </div>
            
        </section>
    )
}

export default Banner