import { useTranslation } from 'react-i18next'
import React from 'react'

const ChooseUs = () => {
    const { t } = useTranslation();
    return (
        <section className="why-choose-two pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("Why You Choose Us Among Other Job Site?")}</h2>
                    <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="choose-card">
                            <i className="flaticon-resume"></i>
                            <h3>{t("Advertise Job")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="choose-card">
                            <i className="flaticon-recruitment"></i>
                            <h3>{t("Recruiter Profiles")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div className="choose-card">
                            <i className="flaticon-employee"></i>
                            <h3>{t("Find Your Dream Job")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChooseUs