import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const JobModal = (props) => {
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const { t } = useTranslation();

  return (
    <Modal
      {...props}
      size="sm"
      centered
    >
      <Modal.Body className={lng === 'arab' ? 'forceArabicFont' : 'forceEnglishFont'} style={{ direction: lng === 'arab' ? 'rtl' : 'ltr', display: 'flex', flexDirection: 'column', gap: '10px', padding:'5px' }}>
        <div className='modalHeader'>
          <i onClick={() => props.onHide()} class='bx bx-x'></i>
        </div>
        {props.dataTransfer.status === 1 || props.dataTransfer.status === 2 || props.dataTransfer.status === 4?
        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', flexDirection:'column', gap:'15px', padding:'10px'}}>
            <label style={{fontSoze: '14px', textAlign: 'center'}}>{t(`${props.dataTransfer.message}`)}</label>
            <Link className="uk-button uk-button-default jobDetailsBtn2" to='/authentication'>{props.dataTransfer.status === 2? t("Complete Registeration"):props.dataTransfer.status === 4? t("Add more information"):t("Login")}</Link>
        </div>:
        props.dataTransfer.status === 3 || props.dataTransfer.status === 5?
        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', flexDirection:'column', gap:'15px', padding:'10px'}}>
            <label style={{fontSoze: '14px', textAlign: 'center'}}>{t(`${props.dataTransfer.message}`)}</label>
        </div>:
        null
        
    }
      </Modal.Body>
    </Modal>
  )
}

export default JobModal