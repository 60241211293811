import { FETCH_ARTICLE, ARTICLE_LOADED, LOAD_ARTICLE } from '../../constants/actionTypes'

export default (article = { article: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_ARTICLE:
            return { ...article, article: action.payload, loading: false };
        case LOAD_ARTICLE:
            return { ...article, loading: true };
        case ARTICLE_LOADED:
            return { ...article, loading: false };
        default:
            return article;
    }
}