import { FETCH_SUBJECT, SUBJECT_LOADED, LOAD_SUBJECT } from '../../constants/actionTypes'

export default (subject = { subject: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_SUBJECT:
            return { ...subject, subject: action.payload, loading: false };
        case LOAD_SUBJECT:
            return { ...subject, loading: true };
        case SUBJECT_LOADED:
            return { ...subject, loading: false };
        default:
            return subject;
    }
}