import { JOB_TITLES, LOADING_JOB_TITLES, LOADED_JOB_TITLES } from "../../constants/actionTypes";

export default (jobTitles = { jobTitles: [], loading: false }, action) => {
    switch (action.type) {
        case JOB_TITLES:
            return { ...jobTitles, jobTitles: action.data, loading: false };
        case LOADING_JOB_TITLES:
            return { ...jobTitles, loading: true };
        case LOADED_JOB_TITLES:
            return { ...jobTitles, loading: false };
        default:
            return jobTitles;
    }
}