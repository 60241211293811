import React, { useEffect } from "react";
import "./AcademyProfile.css";
import profile from "../../assets/profile1.jpg";
import pic1 from "../../assets/pic1.jpg";
import { useDispatch, useSelector } from "react-redux";
import JobItem from "../jobOpenings/job_listItem/Job_Item";
import { useParams } from 'react-router-dom'
import { getAcademyDetails } from "../../redux/actions/academy_details";
import Spinner from "../ui/spinner/Spinner";
import NoDataFound from "../ui/noDataFound/NoDataFound";
import { useHistory } from 'react-router-dom';
import { t } from "i18next";

const AcademyProfile = () => {
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const { academy, loading } = useSelector((state) => state.academyDetails);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  useEffect(() => {
    dispatch(getAcademyDetails(id, history))
  }, [])
  return (
      <div style={{direction: lng === 'arab'? 'rtl':'ltr'}} className="academy-profile">
        <div className="row no-gutters">
          <div className="col-xl-12 profile-col">
            <div className="profile-back">
              <img src={academy?.banner || profile} alt="" />
            </div>
            <div style={lng === 'arab'? {paddingLeft: '0px', paddingRight: '1rem'}:null} className="profile-pic d-flex">
              <img style={lng === 'arab'? {marginRight: '0px', marginLeft: '2rem'}:null} src={academy?.avatar || pic1} alt="" />
              <div className="profile-info2">
                <h2 className="mb-0">{academy?.name || t("N/A")}</h2>
                <h4>{academy?.specialization || t("N/A")}</h4>
                <span className="d-block">
                  <i style={lng === 'arab'? {marginRight: '0px', marginLeft: '.5rem'}:{marginRight: '.5rem', marginLeft: '0px'}} className="fas fa-map-marker-alt"></i>
                  {academy?.location?
                  <>
                  {academy?.location?.country + ", " + academy?.location?.state + "- " + academy?.location?.city || "location"}
                  </>:t('N/A')
                  }
                  
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="all_jibs_wrapper">
        {loading ?
          <Spinner/>
          :
          <div className="jobs_list blog-style-two">
            {academy?.jobs?.length === 0 ?
              <NoDataFound apiMsg={t("No Available Jobs For This Academy")}/>:
              academy?.jobs?.map((item, index) => (
                <JobItem colorOfText="red" data={item} key={index} name={academy?.name || "name"} avatar={academy?.avatar || pic1} />
              ))
            }
          </div>}
        </div>
      </div>
  );
};

export default AcademyProfile;
