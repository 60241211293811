import { INCREASE_VIEWS, LOADED_INCREASE_VIEWS, LOADING_INCREASE_VIEWS } from '../../constants/actionTypes'

export default (increaseViews = { increaseViews: [], loading: false }, action) => {
    switch (action.type) {
        case INCREASE_VIEWS:
            return { ...increaseViews, increaseViews: action.payload, loading: false };
        case LOADING_INCREASE_VIEWS:
            return { ...increaseViews, loading: true };
        case LOADED_INCREASE_VIEWS:
            return { ...increaseViews, loading: false };
        default:
            return increaseViews;
    }
}