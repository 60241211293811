import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitizenships,
  getGender,
  getJobTitle,
  getLanguages,
} from "../../../../../redux/actions/registeration";
import { toastAlert } from "../../../../../layouts/nav/toastAlert";
import { Link } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Select from "react-select";
import LogoFilePicker from "../../../commonComponents/LogoFilePicker";
import upload from "../../../../../assets/upload.png";
import userPlaceHolder from "../../../../../assets/user.png";
import { Country, State, City } from "country-state-city";

let city;
const StepsOne = ({
  setLoadingModalShow,
  setTeacherRegStep1,
  teacherRegStep1,
  setViewOuter,
  profileState,
  setProfileState,
  submitFirstStep,
  resume,
  setResume,
  setStep,
}) => {
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const handleState = (e) => {
    setTeacherRegStep1({ ...teacherRegStep1, state: e.target.value });
    const stateCode = State.getAllStates()?.filter(item => item.name === e.target.value)?.slice(0)?.shift()?.isoCode
    console.log('StateCode', stateCode)
    console.log('CitiesOfStateCode', City.getAllCities()?.filter(item => item.stateCode === stateCode))
    setCities(City.getAllCities()?.filter(item => item.stateCode === stateCode));
  };
  const handleCountry = (e) => {
    setTeacherRegStep1({ ...teacherRegStep1, country: e.target.value });
    const countryCode = Country.getAllCountries()?.filter(item => item.name === e.target.value)?.slice(0)?.shift()?.isoCode
    console.log('countryCode', countryCode)
    console.log('StatesOfCountryCode', State.getStatesOfCountry(countryCode))
    setStates(State.getStatesOfCountry(countryCode));
    setCities([])
  }
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const [profileFile, setProfileFile] = useState("");
  const jobTitles = useSelector((state) => state?.jobTitles?.jobTitles);
  const jobTitleLoading = useSelector((state) => state?.jobTitles?.loading);
  const citizenships = useSelector(
    (state) => state?.citizenships?.citizenships
  );
  const citizenshipsLoading = useSelector(
    (state) => state?.citizenships?.loading
  );
  const gender = useSelector((state) => state?.gender?.gender);
  const genderLoading = useSelector((state) => state?.gender?.loading);
  const languages = useSelector((state) => state?.languages?.languages);
  const languagesLoading = useSelector((state) => state?.languages?.loading);
  const [view, setView] = useState(0);
  useEffect(() => {
    setLoadingModalShow(
      jobTitleLoading ||
        citizenshipsLoading ||
        genderLoading ||
        languagesLoading
    );
  }, [jobTitleLoading, citizenshipsLoading, genderLoading, languagesLoading]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getJobTitle(lng === "arab" ? 1 : 2));
    dispatch(getCitizenships(lng === "arab" ? 1 : 2));
    dispatch(getGender(lng === "arab" ? 1 : 2));
    dispatch(getLanguages(lng === "arab" ? 1 : 2));
  }, []);
  const handleStepOne = (e) => {
    e.preventDefault();
    setLoadingModalShow(true);
    let errorArr = [];
    if (!teacherRegStep1.job_title_id) {
      errorArr.push(t("Please select a vaild job title"));
    }
    if (errorArr.length > 0) {
      toastAlert(errorArr, undefined, "warning");
      setLoadingModalShow(false);
      return false;
    }
    setLoadingModalShow(false);
    setView(2);
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <>
      <div className="green-bg" />
      {view === 0 ? (
        <form onSubmit={handleStepOne} className="login_form">
          <div className="regular_login">
            <div style={{ width: "100%" }} className="row">
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">
                  {t("Which job title fits you best?")}*
                </label>
                <select
                  value={teacherRegStep1.job_title_id}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      job_title_id: e.target.value,
                    })
                  }
                  className="form_control selectInput"
                >
                  {jobTitles?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">
                  {t("Do you have an ESL teaching certificate?")}*
                </label>
                <select
                  value={teacherRegStep1.has_esl_teaching_certificate}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      has_esl_teaching_certificate: e.target.value,
                    })
                  }
                  className="form_control selectInput"
                >
                  <option value={1}>{t("Yes")}</option>
                  <option value={0}>{t("No")}</option>
                </select>
              </div>
              <div className="widthMedia form-group mb-4 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">
                  {t("Do you have a teaching license or credential?")}*
                </label>
                <select
                  value={teacherRegStep1.has_teaching_license_or_credential}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      has_teaching_license_or_credential: e.target.value,
                    })
                  }
                  className="form_control selectInput"
                >
                  <option value={1}>{t("Yes")}</option>
                  <option value={0}>{t("No")}</option>
                </select>
              </div>
              <div className="widthMedia form-group mb-4 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">
                  {t("interesting in online teaching?")}*
                </label>
                <select
                  value={teacherRegStep1.interested_online_teaching}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      interested_online_teaching: e.target.value,
                    })
                  }
                  className="form_control selectInput"
                >
                  <option value={1}>{t("Yes")}</option>
                  <option value={0}>{t("No")}</option>
                </select>
              </div>
            </div>

            <button
              id="loginSubmit"
              type="submit"
              className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
              style={{
                backgroundColor: "#008c77",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {t("Next")}
            </button>
            <div className="haveAnAccount">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  padding: "20px 20px 0px 20px",
                }}
                className="widthMedia form-group col-md-12 col-sm-12 col-lg-12"
              >
                <Link
                  style={{ fontSize: "11px", color: "gray" }}
                  to="#"
                  onClick={() => setViewOuter(0)}
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <form className="login_form" onSubmit={submitFirstStep}>
          <div className="regular_login">
            <div style={{ width: "100%", marginRight: "0px" }} className="row">
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Upload your Profile Picture")}*
                </label>
                <ul style={{ listStyle: "none" }}>
                  <li>Maximum Size 10 MB</li>
                  <li>Recommended Files:- jpg, png, jpeg</li>
                </ul>
                <LogoFilePicker
                  setProfileFile={setProfileFile}
                  LogoState={profileState}
                  setLogoState={setProfileState}
                >
                  <div style={{ cursor: "pointer" }} className="userProfile">
                    <img
                      src={profileFile ? profileFile : userPlaceHolder}
                      alt="upload_icon"
                    />
                  </div>
                </LogoFilePicker>
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("First Name")}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Gamal")}
                  value={teacherRegStep1.first_name}
                  name="first_name"
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      first_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Last Name")}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Hazim")}
                  value={teacherRegStep1.last_name}
                  name="last_name"
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      last_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Gender")}*</label>
                <select
                  value={teacherRegStep1.gender_id}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      gender_id: e.target.value,
                    })
                  }
                  name="gender_id"
                  className="form-control"
                >
                  {gender.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Date of Birth")}*</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    className="form-control"
                    name="date_of_birth"
                    format="dd/MM/yyyy"
                    value={teacherRegStep1.date_of_birth}
                    maxDate={`${formatDate(
                      new Date().setFullYear(new Date().getFullYear() - 18)
                    )}`}
                    disableFuture
                    onChange={(date) =>
                      setTeacherRegStep1({
                        ...teacherRegStep1,
                        date_of_birth: date,
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Country")}*</label>
                <select
                  value={teacherRegStep1.country}
                  onChange={(e) => handleCountry(e)}
                  name="country"
                  className="form-control"
                >
                  <option disabled value=''>{t("Select Country")}</option>
                  {Country.getAllCountries()?.map((item, i) => (
                    <option key={i} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("State")}*</label>
                <select
                  disabled={!teacherRegStep1?.country}
                  value={teacherRegStep1.state}
                  onChange={(e) => handleState(e)}
                  name="state"
                  className="form-control"
                >
                  <option disabled value=''>{t("Select State")}</option>
                  {states?.map((item, i) => (
                    <option key={i} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("City")}*</label>
                <select
                  disabled={!teacherRegStep1?.state}
                  value={teacherRegStep1.city}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      city: e.target.value,
                    })
                  }
                  name="city"
                  className="form-control"
                >
                  <option disabled value=''>{t("Select City")}</option>
                  {cities?.map((item, i) => (
                    <option key={i} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">{t("Citizenship(s)")}*</label>
                <Select
                  onChange={(selectedOption) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      citizenships: selectedOption,
                    })
                  }
                  value={teacherRegStep1.citizenships}
                  placeholder={t("Select citizenship(s)")}
                  options={citizenships?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">{t("Fluent Languages")}*</label>
                <Select
                  onChange={(selectedOption) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      languages: selectedOption,
                    })
                  }
                  value={teacherRegStep1.languages}
                  placeholder={t("Select Language(s)")}
                  options={languages?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">{t("Contact Number")}*</label>
                <PhoneInput
                  id="phone"
                  className="form-control"
                  style={{ display: "flex" }}
                  placeholder={t("Enter phone number")}
                  value={teacherRegStep1.contact_number}
                  onChange={(phone) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      contact_number: phone,
                    })
                  }
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Upload your resume or CV")}
                </label>
                <ul style={{ listStyle: "none" }}>
                  <li>Maximum Size 10 MB</li>
                  <li>Recommended Files:- doc, docx, pdf</li>
                </ul>
                <LogoFilePicker LogoState={resume} setLogoState={setResume}>
                  <div
                    style={{ cursor: "pointer" }}
                    className="custom_file_input"
                  >
                    <img src={upload} alt="upload_icon" />
                  </div>
                </LogoFilePicker>
              </div>
              <div
                style={{ display: "flex", gap: "7px" }}
                className="form-check"
              >
                <input
                  style={{
                    float: "unset",
                    marginRight: "unset",
                    marginLeft: "unset",
                  }}
                  checked={teacherRegStep1.allow_contact_directly == 1}
                  value={teacherRegStep1.allow_contact_directly}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      allow_contact_directly:
                        teacherRegStep1.allow_contact_directly === 1 ? 0 : 1,
                    })
                  }
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label">
                  {t(
                    "I consent to Madares receiving this document and sharing it with employers"
                  )}
                </label>
              </div>
              <div
                style={{ display: "flex", gap: "7px" }}
                className="form-check mt-3 mb-4"
              >
                <input
                  style={{
                    float: "unset",
                    marginRight: "unset",
                    marginLeft: "unset",
                  }}
                  checked={teacherRegStep1.allow_share_docs == 1}
                  value={teacherRegStep1.allow_share_docs}
                  onChange={(e) =>
                    setTeacherRegStep1({
                      ...teacherRegStep1,
                      allow_share_docs:
                        teacherRegStep1.allow_share_docs === 0 ? 1 : 0,
                    })
                  }
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label">
                  {t("Allow hiring schools to contact me directly")}
                </label>
              </div>
            </div>
            <button
              id="loginSubmit"
              type="submit"
              className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
              style={{
                backgroundColor: "#008c77",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {t("Submit")}
            </button>
            <div className="haveAnAccount">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  padding: "20px 20px 0px 20px",
                }}
                className="widthMedia form-group col-md-12 col-sm-12 col-lg-12"
              >
                <Link
                  style={{ fontSize: "11px", color: "gray" }}
                  to="#"
                  onClick={() => setView(0)}
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default StepsOne;
