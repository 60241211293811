export const FETCH_ABOUT = "FETCH_ABOUT";
export const ABOUT_LOADED = "ABOUT_LOADED";
export const LOAD_ABOUT = "LOAD_ABOUT";
export const FETCH_FAQ = "FETCH_FAQ";
export const FAQ_LOADED = "FAQ_LOADED";
export const LOAD_FAQ = "LOAD_FAQ";
export const AUTH_SUBTYPE = "AUTH_SUBTYPE";
export const LOADING_USER_INFO = "LOADING_USER_INFO";
export const LOADED_USER_INFO = "LOADED_USER_INFO";
export const JOB_TITLES = "JOB_TITLES";
export const LOADING_JOB_TITLES = "LOADING_JOB_TITLES";
export const LOADED_JOB_TITLES = "LOADED_JOB_TITLES";

export const DEGREES = "DEGREES";
export const LOADING_DEGREES = "LOADING_DEGREES";
export const LOADEN_DEGREES = "LOADEN_DEGREES";

export const CITIZENSHIPS = "CITIZENSHIPS";
export const LOADING_CITIZENSHIPS = "LOADING_CITIZENSHIPS";
export const LOADED_CITIZENSHIPS = "LOADED_CITIZENSHIPS";

export const POPULAR_CITIES = "POPULAR_CITIES";
export const LOADING_POPULAR_POPULAR_CITIES = "LOADING_POPULAR_POPULAR_CITIES";
export const LOADED_POPULAR_POPULAR_CITIES = "LOADED_POPULAR_POPULAR_CITIES";

export const FOOTER_JOB_TYPES = "FOOTER_JOB_TYPES";
export const LOADING_FOOTER_JOB_TYPES = "LOADING_FOOTER_JOB_TYPES";
export const LOADED_FOOTER_JOB_TYPES = "LOADED_FOOTER_JOB_TYPES";

export const CURRICULUMS = "CURRICULUMS";
export const LOADING_CURRICULUMS = "LOADING_CURRICULUMS";
export const LOADED_CURRICULUMS = "LOADED_CURRICULUMS";

export const GENDER = "GENDER";
export const LOADING_GENDER = "LOADING_GENDER";
export const LOADED_GENDER = "LOADED_GENDER";

export const GRADES = "GRADES";
export const LOADING_GRADES = "LOADING_GRADES";
export const LOADED_GRADES = "LOADED_GRADES";

export const LANGUAGES = "LANGUAGES";
export const LOADING_LANGUAGES = "LOADING_LANGUAGES";
export const LOADED_LANGUAGES = "LOADED_LANGUAGES";

export const SUBJECTS = "SUBJECTS";
export const LOADING_SUBJECTS = "LOADING_SUBJECTS";
export const LOADED_SUBJECTS = "LOADED_SUBJECTS";

export const LICENSE = "LICENSE";
export const LOADING_LICENSE = "LOADING_LICENSE";
export const LOADED_LICENSE = "LOADED_LICENSE";

// export const FETCH_TEACHER = "FETCH_TEACHER";
// export const TEACHER_LOADED = "TEACHER_LOADED";
// export const LOAD_TEACHER = "LOAD_TEACHER";
export const FETCH_SCHOOL = "FETCH_SCHOOL";
export const SCHOOL_LOADED = "SCHOOL_LOADED";
export const LOAD_SCHOOL = "LOAD_SCHOOL";
export const FETCH_BLOG = "FETCH_BLOG";
export const BLOG_LOADED = "BLOG_LOADED";
export const LOAD_BLOG = "LOAD_BLOG";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const ARTICLE_LOADED = "ARTICLE_LOADED";
export const LOAD_ARTICLE = "LOAD_ARTICLE";
export const FETCH_JOB = "FETCH_JOB";
export const JOB_LOADED = "JOB_LOADED";
export const LOAD_JOB = "LOAD_JOB";
export const FETCH_AVAILABLE_JOB = "FETCH_AVAILABLE_JOB";
export const AVAILABLE_JOB_LOADED = "AVAILABLE_JOB_LOADED";
export const LOAD_AVAILABLE_JOB = "LOAD_AVAILABLE_JOB";
export const FETCH_SUBJECT = "FETCH_SUBJECT";
export const SUBJECT_LOADED = "SUBJECT_LOADED";
export const LOAD_SUBJECT = "LOAD_SUBJECT";
export const FETCH_BANNER = "FETCH_BANNER";
export const BANNER_LOADED = "BANNER_LOADED";
export const LOAD_BANNER = "LOAD_BANNER";
export const FETCH_JOB_LEVEL = "FETCH_JOB_LEVEL";
export const JOB_LEVEL_LOADED = "JOB_LEVEL_LOADED";
export const LOAD_JOB_LEVEL = "LOAD_JOB_LEVEL";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FETCH_ALL_JOB_LEVELS = "FETCH_ALL_JOB_LEVELS";
export const LOAD_JOB_LEVELS = "LOAD_JOB_LEVELS";
export const LOADED_JOB_LEVELS = "LOADED_JOB_LEVELS";
export const LOAD_SIZES = "LOAD_SIZES";
export const FETCH_ALL_SIZES = "FETCH_ALL_SIZES";
export const LOADED_SIZES = "LOADED_SIZES";
export const LOAD_YEARS = "LOAD_YEARS";
export const FETCH_ALL_YEARS = "FETCH_ALL_YEARS";
export const LOADED_YEARS = "LOADED_YEARS";
export const SIGN_UP_FIRST_AUTH = "SIGN_UP_FIRST_AUTH";
export const LOAD_SKILLS = "LOAD_SKILLS";
export const FETCH_ALL_SKILLS = "FETCH_ALL_SKILLS";
export const LOADED_SKILLS = "LOADED_SKILLS";
export const LOAD_AVAILABILITIES = "LOAD_AVAILABILITIES";
export const FETCH_ALL_AVAILABILITES = "FETCH_ALL_AVAILABILITES";
export const LOADED_AVAILABILITIES = "LOADED_AVAILABILITIES";

export const INCREASE_VIEWS = "INCREASE_VIEWS";
export const LOADING_INCREASE_VIEWS = "LOADING_INCREASE_VIEWS";
export const LOADED_INCREASE_VIEWS = "LOADED_INCREASE_VIEWS";

export const ACADEMY_DETAILS = "ACADEMY_DETAILS";
export const LOADING_ACADEMY_DETAILS = "LOADING_ACADEMY_DETAILS";
export const LOADED_ACADEMY_DETAILS = "LOADED_ACADEMY_DETAILS";

export const SUBJECT_DETAILS = "SUBJECT_DETAILS";
export const LOADING_SUBJECT_DETAILS = "LOADING_SUBJECT_DETAILS";
export const LOADED_SUBJECT_DETAILS = "LOADED_SUBJECT_DETAILS";

export const MAXIMUM_SALARY = "MAXIMUM_SALARY";
export const LOADING_MAXIMUM_SALARY = "LOADING_MAXIMUM_SALARY";
export const LOADED_MAXIMUM_SALARY = "LOADED_MAXIMUM_SALARY";

export const POPULAR_JOB_TITLES = "POPULAR_JOB_TITLES";
export const LOADING_POPULAR_JOB_TITLES = "LOADING_POPULAR_JOB_TITLES";
export const LOADED_POPULAR_JOB_TITLES = "LOADED_POPULAR_JOB_TITLES";

export const FETCH_SINGLE_ARTICLE = "FETCH_SINGLE_ARTICLE";
export const LOAD_SINGLE_ARTICLE = "LOAD_SINGLE_ARTICLE";
export const SINGLE_ARTICLE_LOADED = "SINGLE_ARTICLE_LOADED";


