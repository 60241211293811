import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { ACADEMY_DETAILS, LOADING_ACADEMY_DETAILS, LOADED_ACADEMY_DETAILS } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getAcademyDetails = (id, history) => async(dispatch) => {
    dispatch({ type: LOADING_ACADEMY_DETAILS });
    try {
        var { data } = await api.getAcademyDetails(id);
        await dispatch({ type: ACADEMY_DETAILS, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_ACADEMY_DETAILS });
        checkErr(error, false, toastAlert, false, history.push('/'), dispatch);
    }
}