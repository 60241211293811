import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { POPULAR_CITIES, LOADING_POPULAR_POPULAR_CITIES, LOADED_POPULAR_POPULAR_CITIES } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getPopularCities = (lang) => async(dispatch) => {
    dispatch({ type: LOADING_POPULAR_POPULAR_CITIES });
    try {
        var { data } = await api.getPopularCities(lang);
        await dispatch({ type: POPULAR_CITIES, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_POPULAR_POPULAR_CITIES });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}