import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurriculums,
  getGrades,
  getSubjects,
} from "../../../../../redux/actions/registeration";
import { Link } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "react-phone-number-input/style.css";
import { CountryDropdown } from "react-country-region-selector";
import Select from "react-select";

const StepsFour = ({
  setLoadingModalShow,
  teacherExperience,
  expChange,
  removeExpFeild,
  addMoreExpFeilds,
  submitExp,
}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const curriculums = useSelector((state) => state?.curriculums?.curriculums);
  const curriculumsLoading = useSelector(
    (state) => state?.curriculums?.loading
  );
  const grades = useSelector((state) => state?.grades?.grades);
  const gradesLoading = useSelector((state) => state?.grades?.loading);
  const subjects = useSelector((state) => state?.subjects?.subjects);
  const subjectsLoading = useSelector((state) => state?.subjects?.loading);
  useEffect(() => {
    setLoadingModalShow(curriculumsLoading || subjectsLoading || gradesLoading);
  }, [curriculumsLoading, subjectsLoading, gradesLoading]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCurriculums(lng === "arab" ? 1 : 2));
    dispatch(getGrades(lng === "arab" ? 1 : 2));
    dispatch(getSubjects(lng === "arab" ? 1 : 2));
  }, []);
  return (
    <>
      <div className="green-bg" />
      <form className="login_form" onSubmit={submitExp}>
        <div className="regular_login">
          {teacherExperience.map((item, i) => (
            <div
              key={i}
              style={
                i === 0
                  ? { width: "100%", marginRight: "0px" }
                  : {
                      borderTop: "1px solid lightgray",
                      width: "100%",
                      marginRight: "0px",
                      paddingTop: "10px",
                      borderTopRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      position: "relative",
                    }
              }
              className="row"
            >
              {i === 0 ? null : (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: lng === "arab" ? "0px" : "auto",
                    right: lng === "arab" ? "auto" : "0px",
                    width: "auto",
                  }}
                >
                  <button
                    onClick={() => removeExpFeild(i)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#008c77"
                      id="Layer_1"
                      dataName="Layer 1"
                      viewBox="0 0 24 24"
                      width="15"
                      height="15"
                    >
                      <path d="M13.93,12L21.666,2.443c.521-.644,.422-1.588-.223-2.109-.645-.522-1.588-.421-2.109,.223l-7.334,9.06L4.666,.557c-1.241-1.519-3.56,.357-2.332,1.887l7.736,9.557L2.334,21.557c-.521,.644-.422,1.588,.223,2.109,.64,.519,1.586,.424,2.109-.223l7.334-9.06,7.334,9.06c.524,.647,1.47,.742,2.109,.223,.645-.521,.744-1.466,.223-2.109l-7.736-9.557Z" />
                    </svg>
                  </button>
                </div>
              )}
              {/* <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Experience Type")}*</label>
                <select
                  value={item.experience_type}
                  name="experience_type"
                  onChange={(e) => expChange(e, i)}
                  className="form_control selectInput"
                >
                  <option value={t("Teaching")}>{t("Teaching")}</option>
                  <option value={t("Educational Administration")}>
                    {t("Educational Administration")}
                  </option>
                  <option value={t("Relevant work experience")}>
                    {t("Relevant work experience")}
                  </option>
                </select>
              </div> */}
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Position Title")}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Computer Teacher")}
                  value={item.title}
                  name="title"
                  onChange={(e) => expChange(e, i)}
                  required
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("School or District")}*</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Academy Name")}
                  value={item.school}
                  name="school"
                  onChange={(e) => expChange(e, i)}
                  required
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("Country")}*</label>
                <CountryDropdown
                  className="form-control"
                  defaultOptionLabel={t("Select Country")}
                  value={item.country}
                  name="country"
                  onChange={(val) =>
                    expChange({ target: { value: val, name: "country" } }, i)
                  }
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">
                  {t("Curriculum you taught")}*
                </label>
                <select
                  value={item.curriculum_id}
                  name="curriculum_id"
                  onChange={(e) => expChange(e, i)}
                  className="form-control"
                >
                  {curriculums?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Grade(s) you taught")}*
                </label>
                <Select
                  value={item.grades}
                  name="grades"
                  onChange={(selectedOption) =>
                    expChange(
                      { target: { value: selectedOption, name: "grades" } },
                      i
                    )
                  }
                  placeholder={t("Select Grade(s)")}
                  options={grades?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                <label className="text-label">
                  {t("Subject(s) you taught")}*
                </label>
                <Select
                  value={item.subjects}
                  name="subjects"
                  onChange={(selectedOption) =>
                    expChange(
                      { target: { value: selectedOption, name: "subjects" } },
                      i
                    )
                  }
                  placeholder={t("Select Subject(s)")}
                  options={subjects?.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                  }))}
                  isMulti
                />
              </div>
              <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                <label className="text-label">{t("From")}*</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label=""
                    clearable
                    className="form-control"
                    name="date_of_birth"
                    format="dd/MM/yyyy"
                    value={item.start_date}
                    onChange={(date) =>
                      expChange(
                        { target: { value: date, name: "start_date" } },
                        i
                      )
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              {item.still_work_there == "1" ? null : (
                <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
                  <label className="text-label">{t("To")}*</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label=""
                      clearable
                      className="form-control"
                      name="date_of_birth"
                      format="dd/MM/yyyy"
                      value={item.end_date}
                      onChange={(date) =>
                        expChange(
                          { target: { value: date, name: "end_date" } },
                          i
                        )
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}

              <div
                // style={{ display: "flex", justifyContent: "space-between" }}
                // className="form-check mt-3 mb-4"
                className="row"
              >
                <div 
                style={{ display: "flex", gap: "7px" }}
                className="col-md-12 col-sm-12 col-lg-6 mb-3"
                >
                  <input
                  style={{
                    float: "unset",
                    marginRight: "unset",
                    marginLeft: "unset",
                  }}
                  checked={item.still_work_there == "1"}
                  value={item.still_work_there}
                  onChange={() =>
                    expChange(
                      {
                        target: {
                          value: item.still_work_there == "0" ? "1" : "0",
                          name: "still_work_there",
                        },
                      },
                      i
                    )
                  }
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label">
                  {t("I am currently working in this role")}
                </label>
                </div>
                <div
                  className="col-md-12 col-sm-12 col-lg-6 pb-5 mb-3"
                  // style={{ display: "flex", justifyContent: "flex-end" }}
                >
                 
                  <button
                    style={{
                      // fontSize: "15px",
                      // color: "gray",
                      // fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "#008c77",
                    }}
                    to="#"
                    onClick={() => addMoreExpFeilds()}
                  >
                     <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-square-fill"
                      viewBox="0 0 16 16"
                      color="#008c77"
                      onClick={() => addMoreExpFeilds()}
                    >
                      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                    </svg>
                   {t("Add another experience")}
                  </button>
                </div>
              </div>
            </div>
          ))}
          <button
            id="loginSubmit"
            type="submit"
            className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
            style={{
              backgroundColor: "#008c77",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {t("Submit")}
          </button>
        </div>
      </form>
    </>
  );
};

export default StepsFour;
