import { FETCH_BANNER, BANNER_LOADED, LOAD_BANNER } from '../../constants/actionTypes'

export default (banner = { banner: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_BANNER:
            return { ...banner, banner: action.payload, loading: false };
        case LOAD_BANNER:
            return { ...banner, loading: true };
        case BANNER_LOADED:
            return { ...banner, loading: false };
        default:
            return banner;
    }
}