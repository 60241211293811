import React, { useState, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import { signIn, signInWithSocial } from "../../../../../redux/actions/Auth";
import { useDispatch } from "react-redux";
import loginImg from "../../../../../assets/authBC.png";
import LoadingModal from "../../../../../layouts/LoadingPopUp";
import { toastAlert } from "../../../../../layouts/nav/toastAlert";
import { t } from "i18next";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { useLinkedIn } from "react-linkedin-login-oauth2";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userLogin, setUserLogin] = useState({
    email: "mahmoudgawish11@gmail.com",
    password: "123456789",
  });
  const [loadingModalShow, setLoadingModalShow] = useState(false);
  const [codeLinkedIn, setCodeLinkedIn] = useState("");
  const googleRef = useRef(null);
  const facebookRef = useRef(null);
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const onLoginStart = useCallback(() => {
    props.setLoadingModalShow(true);
  }, []);
  const loginWithSocialInner = (loginType, token) => {
    props.setLoadingModalShow(true);
    dispatch(signInWithSocial(loginType, token, props.setLoadingModalShow, history));
  };
  const handleLogin = (e) => {
    e.preventDefault();
    props.setLoadingModalShow(true);
    if (!userLogin.email || !userLogin.password) {
      props.setLoadingModalShow(false);
      toastAlert(t("Enter your login data"), undefined, "warning");
    } else {
        dispatch(
          signIn(
            userLogin.email,
            userLogin.password,
            props.setLoadingModalShow,
            history
          )
        );
    }
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    onSuccess: (code) => {
      if (code) {
        setCodeLinkedIn(code);
        loginWithSocialInner("linkedin", code);
      }
    },
    onError: (error) => {
      if (!codeLinkedIn) {
        toastAlert(
          t("Error While Trying to Login With Linkedin"),
          undefined,
          "warning"
        );
      }
    },
  });
  return (
        <div className="login_page">
          <div className="login_card_wrapper" style={{ direction: lng === "arab" ? "rtl" : "ltr" }}>
            <h3 className="page_title">{props.title || t("LOGIN")}</h3>
            <div className="cardd overflow-hidden">
              <div style={{ padding: "20px" }} className="card-body innerCardBody">
                <div className="green-bg" />
                <form onSubmit={handleLogin} className="login_form">
                  <div className="regular_login">
                    <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
                      <label className="text-label">{t("Email")}</label>
                      <input
                        required
                        type="email"
                        className="form_control"
                        placeholder={t("Email")}
                        value={userLogin.email}
                        onChange={(e) =>
                          setUserLogin({ ...userLogin, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="widthMedia form-group mb-2 col-md-12 col-sm-12 col-lg-12">
                      <label className="text-label">{t("Password")}</label>
                      <input
                        required
                        type="password"
                        className="form_control"
                        placeholder={t("Password")}
                        value={userLogin.password}
                        onChange={(e) =>
                          setUserLogin({
                            ...userLogin,
                            password: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="widthMedia form-group mb-10 mt-10">
                      <Link
                        style={{ fontSize: "11px", color: "gray" }}
                        to={{pathname: "/authentication", state: { view: 2 }}}
                        // onClick={() => props.setView(2)}
                        // state={ {view: 2}}
                      >
                        {t("Forget Password?")}
                      </Link>
                    </div>
                    <button
                      id="loginSubmit"
                      type="submit"
                      className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
                      style={{
                        backgroundColor: "#008C77",
                        color: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {t("Login")}
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                      width: "80%",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "80%",
                        height: "1px",
                        backgroundColor: "gray",
                      }}
                    />
                    <label
                      style={{
                        textAlign: "center",
                        margin: "0px",
                        fontSize: "15px",
                        color: "gray",
                      }}
                    >
                      {t("or")}
                    </label>
                    <div
                      style={{
                        width: "80%",
                        height: "1px",
                        backgroundColor: "gray",
                      }}
                    />
                  </div>

                  <div className="social_login_btns">
                    <LoginSocialFacebook
                      ref={facebookRef}
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      redirect_uri="https://cvsa.mvp-apps.ae/authentication"
                      auth_type="reauthenticate"
                      // onLogoutFailure={onLogoutFailure}
                      onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      onResolve={({ provider, data }) => {
                        loginWithSocialInner("facebook", data.accessToken);
                      }}
                      onReject={(err) => {
                        props.setLoadingModalShow(false);
                        toastAlert(
                          t("Error While Trying to Login With Facebook"),
                          undefined,
                          "warning"
                        );
                      }}
                    >
                      <i
                        className="fab fa-facebook-f"
                        style={{ color: "#4267B2" }}
                      />
                    </LoginSocialFacebook>
                    <LoginSocialGoogle
                      ref={googleRef}
                      client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      redirect_uri="https://cvsa.mvp-apps.ae/authentication"
                      // onLogoutFailure={onLogoutFailure}
                      onLoginStart={onLoginStart}
                      // onLogoutSuccess={onLogoutSuccess}
                      onResolve={({ provider, data }) => {
                        loginWithSocialInner("google", data.access_token);
                      }}
                      onReject={(err) => {
                        props.setLoadingModalShow(false);
                        toastAlert(
                          t("Error While Trying to Login With Google"),
                          undefined,
                          "warning"
                        );
                      }}
                    >
                      <i
                        className="fab fa-google"
                        style={{
                          background:
                            "-webkit-linear-gradient( right,  #DB4437, #F4B400,#4285F4, #0F9D58)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      />
                    </LoginSocialGoogle>
                    <i
                      onClick={linkedInLogin}
                      className="fab fa-linkedin"
                      style={{ color: "#0072b1" }}
                    />
                  </div>

                  <div className="haveAnAccount">
                    <div className="widthMedia form-group mt-10">
                      <Link
                        style={{ fontSize: "11px", color: "gray" }}
                        to={{pathname: "/authentication", state: { view: 1 }}}
                        // onClick={() => props.setView(1)}
                      >
                        {t("Don't Have Account?")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <LoadingModal
            show={loadingModalShow}
            dialogClassName="modal-60w"
            backdrop="static"
            onHide={() => props.setLoadingModalShow(false)}
          />
        </div>
  );
};

export default Login;
