import { FETCH_ALL_YEARS, LOAD_YEARS, LOADED_YEARS } from '../../constants/actionTypes'
export default (yearsOfTeaching = {yearsOfTeaching: [], loading: false}, action) => {
    switch (action.type) {
        case FETCH_ALL_YEARS:
            return { ...yearsOfTeaching, yearsOfTeaching: action.data, loading: false };
        case LOAD_YEARS:
            return { ...yearsOfTeaching, loading: true };
        case LOADED_YEARS:
            return { ...yearsOfTeaching, loading: false };
        default:
            return yearsOfTeaching;
  }
}
