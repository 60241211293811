import { FETCH_ALL_JOB_LEVELS, LOAD_JOB_LEVELS, LOADED_JOB_LEVELS } from '../../constants/actionTypes'

export default (job_levels = {jobLevelsInner: [], loading: false}, action) => {
    switch (action.type) {
        case FETCH_ALL_JOB_LEVELS:
            return { ...job_levels, jobLevelsInner: action.data, loading: false };
        case LOAD_JOB_LEVELS:
            return { ...job_levels, loading: true };
        case LOADED_JOB_LEVELS:
            return { ...job_levels, loading: false };
        default:
            return job_levels;
  }
}
