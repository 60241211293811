import { FETCH_BLOG, BLOG_LOADED, LOAD_BLOG } from '../../constants/actionTypes'

export default (blog = { blog: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_BLOG:
            return { ...blog, blog: action.payload, loading: false };
        case LOAD_BLOG:
            return { ...blog, loading: true };
        case BLOG_LOADED:
            return { ...blog, loading: false };
        default:
            return blog;
    }
}