import React from 'react'
import './Job_post.css'
import schoolDefault from '../../../../assets/defaultAcademy.svg'
import logoDefault from '../../../../assets//logo.png'
import arrow from '../../../../assets//next.png'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const JobPost = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const userDetails = useSelector((state) => state?.userDetails?.authData);
  const userDetailsLoading = useSelector((state) => state?.userDetails?.loading);
	const history = useHistory()
  const getDays = (date) => {
    let date_1 = new Date(date);
    let date_2 = new Date();
    let difference = - date_1.getTime() + date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return parseInt(TotalDays)
  }
  return (
    <div className='job_post'>
      <img className='job_post_img' src={props?.data?.banner || schoolDefault } alt='job_post_img' />
      <img className='academy_profile' src={props?.data?.avatar || logoDefault } alt='academy_profile' onClick={() => history.push(`/academy-profile/${props.data.id}`)} />
      <div className='details_wrapper'>
      <div className='academy_details_wrapper' style={{ display: 'flex', flexDirection: 'column'}}>
          <label className='job_post_title' onClick={() => history.push(`/academy-profile/${props.data.id}`)}>{props?.data?.name}</label>
          <label className='job_post_location'>
          {
          props.data?.location?
          props.data?.location?.country && props.data?.location?.city?
          props.data?.location?.country + t(', ') + props.data?.location?.city:
          props.data?.location?.country && !props.data?.location?.city?
          props.data?.location?.country:
          props.data?.location?.city:
          'N/A'
        }</label>
        </div>
      {!props?.data?.start_date_of_last_active_job?.start_date?
        <label style={{ justifyContent: lng === 'arab' ? 'flex-start' : null }} className='date'>{t("N/A")}</label>:
        <label style={{ justifyContent: lng === 'arab' ? 'flex-start' : null }} className='date'>
        {
          lng === 'arab' ?
            (t("ago") + ' ' + (getDays(props?.data?.start_date_of_last_active_job?.start_date) == 1 || getDays(props?.data?.start_date_of_last_active_job?.start_date) == 2 ? '' : getDays(props?.data?.start_date_of_last_active_job?.start_date)) + ' ' +
              t(
                getDays(getDays(props?.data?.start_date_of_last_active_job?.start_date)) == 1 || getDays(props?.data?.start_date_of_last_active_job?.start_date) == 100 ? "Days0" :
                getDays(props?.data?.start_date_of_last_active_job?.start_date) == 2 ? "Days1" :
                getDays(props?.data?.start_date_of_last_active_job?.start_date) >= 11 && getDays(props?.data?.start_date_of_last_active_job?.start_date) <= 99 ? 'Days2' :
                      "Days"
              ))
            :
            (getDays(props?.data?.start_date_of_last_active_job?.start_date) + ' ' + t("Days ago"))}
        </label>}
        
        </div>
      <div className='job_post_data_wraper'>
        
        

        <div className='job_post_numbers'>
          <div className='job_post_number_element'>
            <label>{props?.data?.available_active_jobs_count || 0}</label>
            <label>{t("Jobs")}</label>
          </div>
          <div className='hr'></div>
          <div className='job_post_number_element'>
            <label>{props?.data?.available_active_jobs_sum_number_of_vacancies || 0}</label>
            <label>{t("Vacancies")}</label>
          </div>
        </div>
        <Link style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className='apply_now' to={`/academy-profile/${props.data.id}`}>
          <label>{props.userDetails?.type === 'A'? t("View"):t("Apply Now")}</label>
        </Link>
      </div>
    </div>
  )
}

export default JobPost