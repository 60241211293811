/* eslint-disable */

import $ from "jquery";

const fbAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function initFacebookSdk() {
    // wait for facebook sdk to initialize before starting the react app
    const localLang = JSON.parse(localStorage.getItem("language"))?.lang;
    const language = localLang === "en" ? "en_US" : "ar_AR";
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: fbAppId,
            status: false,
            cookie: true,
            xfbml: true,
            version: 'v12.0',
        });
        $(document).trigger('FBSDKLoaded');
    };

    // load facebook sdk script
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id; 
        js.async;
        js.src = `https://connect.facebook.net/${language}/all.js#xfbml=1&appId=${fbAppId}`;
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}