import React, { useEffect, useState } from "react";
import "./JobsFilter.css";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Country, State, City }  from 'country-state-city';

const JobsFilter = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { popularJobs } = useSelector((state) => state.popularJobTitles);
  const footerJobTypes = useSelector(state => state.footerJobTypes.footerJobTypes);
  const search = useLocation().search;
	const typeURL = new URLSearchParams(search).get('typ');
  const [innerJobFilter, setInnerJobFilter] = useState({
    experience: props.jobFilterData.experience,
    postDate: props.jobFilterData.postDate,
    salaryRange: { min: props.jobFilterData.minimum_salary || 0, max: props.jobFilterData.maximum_salary || props?.maxsalary ||0 },
    teacherCategory: props.jobFilterData.teacherCategory,
    city: props.jobFilterData.city?
    {label: props.jobFilterData.city, value: props.jobFilterData.city}:
    ""
    ,
    grade: 
    props.jobFilterData.grade? 
    {label: footerJobTypes?.filter(item => item.id == props.jobFilterData.grade)?.shift()?.name, value: footerJobTypes?.filter(item => item.id == props.jobFilterData.grade)?.shift()?.id}:
    typeURL? {label: footerJobTypes?.filter(item => item.id == typeURL)?.shift()?.name, value: footerJobTypes?.filter(item => item.id == typeURL)?.shift()?.id}: ''
  })
  return (
    <div className="jobs_list_filter">
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Teacher Category")}
        </label>
        <div className="uk-margin">
          <Select
            placeholder={t("Select Category(ies)")}
            name="types"
            onChange={(e) => setInnerJobFilter({...innerJobFilter, teacherCategory: e})}
            value={innerJobFilter.teacherCategory}
            options={popularJobs?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            isMulti
          />
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Grade")}
        </label>
        <div className="uk-margin">
          <Select
            placeholder={t("Select Grade")}
            isDisabled={innerJobFilter.grade && typeURL}
            name="types"
            onChange={(e) => setInnerJobFilter({...innerJobFilter, grade: e})}
            value={innerJobFilter.grade}
            options={footerJobTypes?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
          />
        </div>
      </div>
      <div className="job_filter_box">
          <label style={{ textAlign: lng === "arab" ? "right" : "left" }} className="filter_title">
            {t("City")}*
          </label>
          <div className="uk-margin">
          <Select
            placeholder={t("Select City")}
            name="types"
            onChange={(e) => setInnerJobFilter({...innerJobFilter, city: e})}
            value={innerJobFilter.city}
            options={State.getStatesOfCountry("SA")?.map((item) => ({
              label: item?.name,
              value: item?.name,
            }))}
          />
        </div>
        </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Salary Range")}
        </label>
        <div style={{ direction: "ltr" }} className="uk-margin">
          <InputRange
            value={innerJobFilter.salaryRange}
            step={500}
            maxValue={props?.maxsalary}
            minValue={0}
            onChange={(value) => setInnerJobFilter({...innerJobFilter, salaryRange: value})}
          />
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title "
        >
          {t("Experince")}
        </label>
        <div
          style={{ alignItems: lng === "arab" ? "flex-start" : "flex-start" }}
          className="uk-margin filter_form_radio_wrraper"
        >
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === ''}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: ''})}
            />{" "}
            {t("Any Experince")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 1}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 1})}
            />{" "}
            {t("1 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 2}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 2})}
            />{" "}
            {t("2 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 3}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 3})}
            />{" "}
            {t("3 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 4}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 4})}
            />{" "}
            {t("4 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 5}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 5})}
            />{" "}
            {t("5 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 6}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 6})}
            />{" "}
            {t("6 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 7}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 7})}
            />{" "}
            {t("7 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 8}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 8})}
            />{" "}
            {t("8 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 9}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 9})}
            />{" "}
            {t("9 Year")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio3"
              checked = {innerJobFilter.experience === 10}
              onChange={() => setInnerJobFilter({...innerJobFilter, experience: 10})}
            />{" "}
            {t("10 Year")}
          </label>
        </div>
      </div>
      <div className="job_filter_box">
        <label
          style={{ textAlign: lng === "arab" ? "right" : "left" }}
          className="filter_title"
        >
          {t("Date Posted")}
        </label>
        <div
          style={{ alignItems: lng === "arab" ? "flex-start" : "flex-start" }}
          className="uk-margin filter_form_radio_wrraper"
        >
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked = {innerJobFilter.postDate === ''}
              onChange={() => setInnerJobFilter({...innerJobFilter, postDate: ''})}
            />{" "}
            {t("Any Date")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked = {innerJobFilter.postDate === 'D'}
              onChange={() => setInnerJobFilter({...innerJobFilter, postDate: 'D'})}
            />{" "}
            {t("Last 24 Hours")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked = {innerJobFilter.postDate === 'W'}
              onChange={() => setInnerJobFilter({...innerJobFilter, postDate: 'W'})}
            />{" "}
            {t("Last 7 Days")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked = {innerJobFilter.postDate === 'HM'}
              onChange={() => setInnerJobFilter({...innerJobFilter, postDate: 'HM'})}
            />{" "}
            {t("Last 15 Days")}
          </label>
          <label>
            <input
              className="uk-radio"
              type="radio"
              name="radio2"
              checked = {innerJobFilter.postDate === 'M'}
              onChange={() => setInnerJobFilter({...innerJobFilter, postDate: 'M'})}
            />{" "}
            {t("Last 30 Days")}
          </label>
        </div>
      </div>
      <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%', flexWrap:'wrap', gap:'5px'}}>
        <button onClick={() => props.removeFilter()} className="uk-button uk-button-danger" type="button">
          {t("Remove Filter")}
        </button>
        <button onClick={() => props.handleFilter(innerJobFilter)} className="uk-button uk-button-default" type="button">
          {t("Filter")}
        </button>
        
      </div>
    </div>
  )
};

export default JobsFilter;
