import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const Pagination = ({currentPage, nextPage, lastPage, paramURL}) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [numbersOfPages, setNumbersOfPages] = useState([])
    const location = useLocation()
    useEffect(() => {
        let pagesNumbers = []
        let neededNumbers = []
        let pages = lastPage
        while (pages > 0 ) {
            pagesNumbers.push(pages)
            pages--
        }
        let currentPageIndex = pagesNumbers.indexOf(currentPage)
        if (pagesNumbers.length - 1 === currentPageIndex) {
            if (pagesNumbers.length <= 2) {
                setNumbersOfPages(pagesNumbers.reverse())
            } else {
                neededNumbers.push(pagesNumbers[currentPageIndex])
                neededNumbers.push(pagesNumbers[currentPageIndex - 1])
                neededNumbers.push(pagesNumbers[currentPageIndex - 2])
                setNumbersOfPages(neededNumbers.reverse())
            }
        } else if (currentPageIndex === 0) {
            if (pagesNumbers.length <= 2) {
                setNumbersOfPages(pagesNumbers.reverse())
            } else {
                neededNumbers.push(pagesNumbers[currentPageIndex])
                neededNumbers.push(pagesNumbers[currentPageIndex + 1])
                neededNumbers.push(pagesNumbers[currentPageIndex + 2])
                setNumbersOfPages(neededNumbers.reverse())
            }
        } else {
            neededNumbers.push(pagesNumbers[currentPageIndex])
            neededNumbers.push(pagesNumbers[currentPageIndex + 1])
            neededNumbers.push(pagesNumbers[currentPageIndex - 1])
            setNumbersOfPages(neededNumbers.reverse())
        }
        }, [lastPage])
  return (
    <nav aria-label="Page navigation example">
        <ul style={{ padding: '0px' }} className="pagination justify-content-center">
            <li className={currentPage === 1? "page-item disabled":"page-item"}>
                <Link 
                    to={
                        currentPage === 1?
                        (`${location.pathname}${location.search || ''}`):
                        (`${location.pathname}${paramURL?
                        ('?cit=' + paramURL + '&pg=' + (currentPage - 1))
                        :
                        ('?pg=' + currentPage - 1)
                        }`)
                    } 
                    className="page-link" tabIndex="-1" aria-disabled="true">
                    <i className={lng === 'arab' ? 'bx bx-chevrons-right bx-fade-right' : 'bx bx-chevrons-left bx-fade-left'}></i>
                </Link>
            </li>
            {numbersOfPages.map((number, i) => (
            <li key={i} className="page-item">
                <Link
                    to={
                        currentPage === number? 
                        (`${location.pathname}${location.search || ''}`):
                        (`${location.pathname}${paramURL?
                            ('?cit=' + paramURL + '&pg=' + (number))
                            :
                            ('?pg=' + number)
                        }`)
                    }
                    className={currentPage === number? "page-link active":"page-link"}
                >
                    {number}
                 </Link>
            </li>
            ))}
            <li className={currentPage === lastPage? "page-item disabled":"page-item "}>
                <Link 
                    to={
                        currentPage === lastPage?
                        (`${location.pathname}${location.search || ''}`):
                        (`${location.pathname}${paramURL?
                        ('?cit=' + paramURL + '&pg=' + (currentPage + 1))
                        :
                        ('?pg=' + currentPage + 1)
                        }`)
                    } 
                    className="page-link" >
                    <i className={lng === 'arab' ? 'bx bx-chevrons-left bx-fade-left' : 'bx bx-chevrons-right bx-fade-right'}></i>
                </Link>
            </li>
        </ul>
    </nav>
  )
}

export default Pagination