import React, { useState, useEffect } from 'react'
import './News.css'
import NewsCom from './newsCom/NewsCom'
import "swiper/swiper-bundle.css";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import Spinner from '../../ui/spinner/Spinner';
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import SwiperCore,{ Navigation } from 'swiper';

const News = (props) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [width, setWidth] = useState(window.innerWidth);
    const { article, loading } = useSelector(state => state.article);
    const [view, setView] = useState(1);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    useEffect(() => {
        if (window.innerWidth > 1400) {
          setView(2.7);
        } else if (window.innerWidth > 1200 && window.innerWidth <= 1400) {
          setView(2.7);
        } else if (window.innerWidth > 1070 && window.innerWidth <= 1200) {
          setView(2.7);
        } else if (window.innerWidth > 900 && window.innerWidth <= 1070) {
          setView(2.3);
        } else if (window.innerWidth > 788 && window.innerWidth <= 900) {
          setView(2);
        } else if (window.innerWidth > 600 && window.innerWidth <= 788) {
          setView(1.5);
        } else if (window.innerWidth > 480 && window.innerWidth <= 600) {
          setView(1.2);
        } else if (window.innerWidth > 309 && window.innerWidth <= 480) {
          setView(1);
        } else {
          setView(1);
        }
      }, [width]);
    return (
      <section className="subjects pb-70 pt-40 blog-style-two">
        <div className="container">
          {!props.fromNewsPage?
          <div style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='av_jobs_header'>
            <div className='title_and_breif'>
              <h1>{t("Articles")}</h1>
            </div>
            <Link to='/blogs'>{t("View All")}</Link>
          </div>:null
          }
          {loading ?
            <Spinner />
            :
            !loading && article?.data?.length === 0 ?
            <NoDataFound />
            :
            props.fromNewsPage?
            <div style={{minHeight:'500px'}} className='row'>
              {article?.data?.map((item, index) => (
                <div className='col-lg-4 col-md-6 col-sm-12'>
                  <NewsCom key={index} index={index} data={item}/>
                </div>
              ))}
            </div>:
            <div style={{width: '100%'}}>
              <Swiper
                slidesPerView={view}
                spaceBetween={30}
                loop={article?.data?.length > view ? true : false}
                navigation={true}
                centeredSlides={true}
               >
                  {article?.data?.map((item, index) => (
                    <SwiperSlide key={index}>
                      <NewsCom index={index} data={item}/>
                    </SwiperSlide>
                  ))}
                </Swiper>
            </div>
            }
          </div>
        </section>
    )
}
export default News