/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../../ui/noDataFound/NoDataFound';
import Spinner from '../../ui/spinner/Spinner';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";
import SwiperCore,{ Navigation } from 'swiper';
SwiperCore.use([Navigation]);
const Testimonial = ({ about, loading }) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [view, setView] = useState(1);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    useEffect(() => {
      if (window.innerWidth > 1400) {
        setView(1.5);
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1400) {
        setView(1.5);
      } else if (window.innerWidth > 1070 && window.innerWidth <= 1200) {
        setView(1.5);
      } else if (window.innerWidth > 900 && window.innerWidth <= 1070) {
        setView(1.5);
      } else if (window.innerWidth > 788 && window.innerWidth <= 900) {
        setView(1.2);
      } else if (window.innerWidth > 600 && window.innerWidth <= 788) {
        setView(1);
      } else if (window.innerWidth > 480 && window.innerWidth <= 600) {
        setView(1);
      } else if (window.innerWidth > 309 && window.innerWidth <= 480) {
        setView(1);
      } else {
        setView(1);
      }
    }, [width]);
    return (
        <>
        {loading?
            <Spinner />
        :
        about?.data?.testimonial?.length === 0 ?
            <NoDataFound />
        :
        <div className="testimonial-style-two ptb-100">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("What Client’s Say About Us")}</h2>
                    <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus")}</p>
                </div>
                <div className='testimonial-slider-two owl-carousel owl-theme' style={{width: '100%'}}>
                    {
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        loop={about?.data?.testimonial?.length > 1 ? true : false}
                        navigation={true}
                    >
                        {
                            about?.data?.testimonial?.map((item, index) => (
                            <SwiperSlide style={{display:'flex', alignItems: 'center', justifyContent: 'center'}} key={index}>
                                <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="testimonial-items" key={item.id || 0}>
                                    <div className="testimonial-text">
                                        <i className='flaticon-left-quotes-sign'></i>
                                        <p>{item?.body || "No Body"}</p>
                                    </div>
                                    <div className="testimonial-info-text">
                                        <h3>{item?.name || "No Name"}</h3>
                                        <p>{item?.title || "No Title"}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            ))
                        }
                    </Swiper>}
                </div>
            </div>
        </div>}
        </>
    )
}

export default Testimonial