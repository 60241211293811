import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import {Link, useLocation} from "react-router-dom";
import Spinner from '../../ui/spinner/Spinner'
import parse from 'html-react-parser';
import NoDataFound from '../../ui/noDataFound/NoDataFound';
const Details = ({details, loading}) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const article = useSelector(state => state.article.article);
    const articlesLoading = useSelector(state => state.article.loading);
    return (
        <section className="blog-details-area ptb-100">
            {loading?
            <Spinner/>:
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-dedails-text">
                            <div className="blog-meta">
                                <ul style={{ padding: lng === 'arab' ? '5px' : null }}>
                                    <li style={lng === 'arab'? {marginRight: '0px', marginLeft: '20px'}:null}>
                                        <i style={lng === 'arab'? {marginRight: '0px', marginLeft: '2px'}:null} className='bx bxs-user'></i>
                                        {t('Admin')}
                                    </li>
                                    <li style={lng === 'arab'? {marginRight: '0px', marginLeft: '20px'}:null}>
                                        <i style={lng === 'arab'? {marginRight: '0px', marginLeft: '2px'}:null} className='bx bx-calendar'></i>
                                        {details?.created_at || t('N/A')}
                                    </li>
                                </ul>
                            </div>
                            <div style={{width:'100%'}}>{parse(details?.body || t('N/A')) }</div>
                        </div>
                    </div>
                    {!articlesLoading && article?.data?.filter(outerItem => outerItem?.id !== details?.id).length === 0?
                    <NoDataFound/>:
                    <div style={{marginTop: '40px'}} className="col-lg-12">
                        <div className="blog-widget">
                            <h3>{t('Popular Articles')}</h3>
                            <>
                            {articlesLoading?
                            <Spinner/>:
                            <>
                            {article?.data?.filter(outerItem => outerItem?.id !== details?.id)?.slice(0,4)?.map((item,i) =>
                            <article key={i} className="popular-post">
                                <Link style={lng === 'arab'? {marginRight: '0px', marginLeft: '14px', float:'right'}:null} to={`/blog-details/${item?.id}`} className="blog-thumb">
                                    <img src={item?.image || require('../../../assets/img/blog-detail/popular-post1.jpeg')} alt="blog image" />
                                </Link>
                                <div className="info">
                                    <time dateTime="2021-04-08">{item?.created_at}</time>
                                    <h4>
                                        <Link to={`/blog-details/${item?.id}`}>{item?.title}</Link>
                                    </h4>
                                </div>
                            </article>
                            )}
                            </>
                            }
                            </>
                        </div>
                    </div>}
                </div>
            </div>}
        </section>
    ) 
}

export default Details