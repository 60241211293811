import React, { useState, useEffect } from "react";
import "./JobOpenings.css";
import ReusableBanner from "../ui/reusableBanner/ReusableBanner";
import JobsList from "./jobs_list/JobsList";
import JobsFilter from "./jobs_filter/JobsFilter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMaxSalary } from "../../redux/actions/maximum_salary";
import { getPopularJob } from "../../redux/actions/popular_jobs";
import { useLocation, useHistory } from 'react-router-dom'
import { getJobs } from "../../redux/actions/jobs";
import JobFilterModal from "./jobs_filter/job_filter_modal/JobFilterModal";

const JobOpenings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory()
  const location = useLocation()
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  const { job, loading } = useSelector(state => state.job)
	const currentPage = useSelector((state) => state?.job?.currentPage);
	const nextPage = useSelector((state) => state?.job?.nextPage);
	const lastPage = useSelector((state) => state?.job?.lastPage);
  const footerJobTypes = useSelector(state => state.footerJobTypes.footerJobTypes);
	const footerJobTypesLoading = useSelector(state => state.footerJobTypes.loading);
  const subject = useSelector(state => state.homeSubject.subject);
  const subjectLoading = useSelector(state => state.homeSubject.loading);
  const search = useLocation().search;
	const typeURL = new URLSearchParams(search).get('typ');
  const subjectURL = new URLSearchParams(search).get('sub');
  const pageURL = new URLSearchParams(search).get('pg');
  
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  useEffect(() => {
    dispatch(getMaxSalary())
    dispatch(getPopularJob(lng === "arab" ? 1 : 2))
  }, [])
  const maxSalary= useSelector(state => state.maxSalary.maxSalary)
  const maxSalaryLoading = useSelector(state => state.maxSalary.loading)
  const [inputSearch, setInputSearch] = useState("");
  const [loadingSwitch, setLoadingSwitch] = useState(true);
  const [sortBy, setSortBy] = useState('');
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    if (sortBy === '') {
      setJobs(job)
    } else if (sortBy === 'name') {
      let sortedJobs = job?.slice(0)?.sort((x,y) => {
        if (x?.title?.toUpperCase() < y?.title?.toUpperCase()) {return -1;}
        if (x?.title?.toUpperCase() > y?.title?.toUpperCase()) {return 1;}
        return 0;
      });
      setJobs(sortedJobs)
    } else {
      let sortedJobs = job?.slice(0)?.sort((x,y) => {
        if (x?.publish_salary_information == 1 && y?.publish_salary_information == 1) {
          return parseInt(x?.maximum_salary) - parseInt(y?.maximum_salary)
        } else if (x?.publish_salary_information != 1) {
          return -1
        } else {
          return +1
        }
      });
      setJobs(sortedJobs.reverse())
    }
  }, [job, sortBy])

  // useEffect(() => {
  //   setLoadingSwitch(maxSalaryLoading || loading || footerJobTypesLoading || subjectLoading)
  // }, [maxSalaryLoading, loading, footerJobTypesLoading, subjectLoading])
  const [postDate, setPostDate] = useState("");
  const [teacherCategory, setTeacherCategory] = useState([])
  const [filterModal, setFilterModal] = useState(false)
  const [jobFilterData, setJobFilterData] = useState({
    grade: typeURL || '',
    experience: '',
    postDate: '',
    minimum_salary: '',
    maximum_salary: '',
    teacherCategory: [],
    subject: subjectURL || '',
    city: ''
  })
  useEffect(() => {
    setJobFilterData({
      grade: typeURL || '',
      experience: '',
      postDate: '',
      minimum_salary: '',
      maximum_salary: '',
      teacherCategory: [],
      subject: subjectURL || '',
      city: ''
    })
  }, [typeURL, subjectURL])
  useEffect(() => {
    setLoadingSwitch(true)
    if (search && !typeURL && !pageURL && !subjectURL) {
      return history.push('/jobs')
    }
    // if (typeURL && !footerJobTypesLoading ) {
    //   if (footerJobTypes?.filter(item => item.id == typeURL).length === 0) {
    //     alert('no type')
    //     return history.push('/jobs')
    //   }
    // }
    // if (subjectURL && !subjectLoading ) {
    //   if (subject?.data?.filter(item => item.id == subjectURL).length === 0) {
    //     alert('no subject')
    //     return history.push('/jobs')
    //   }
    // }
    if (pageURL > lastPage) {
      return history.push('/jobs')
    } else if (pageURL <= 0 && pageURL) {
      return history.push('/jobs')
    }
    if (!subjectLoading && !footerJobTypesLoading) {
      dispatch(getJobs(pageURL, lng === 'arab'? 1:2, (inputSearch || ""), jobFilterData, setLoadingSwitch ))
    }
  }, [pageURL, subjectURL, typeURL, footerJobTypesLoading, subjectLoading, inputSearch, jobFilterData])
  const handleFilter = (modalData) => {
    setJobFilterData({
      ...jobFilterData,
      grade: modalData?.grade?.value,
      experience: modalData?.experience,
      postDate: modalData?.postDate,
      minimum_salary: modalData?.salaryRange?.min || 0,
      maximum_salary: modalData?.salaryRange?.max,
      teacherCategory: modalData?.teacherCategory,
      city: modalData?.city?.value
    })
    setFilterModal(false)
  }
  const removeFilter = () => {
    setJobFilterData({
      grade: typeURL || '',
      experience: '',
      postDate: '',
      minimum_salary: '',
      maximum_salary: '',
      teacherCategory: [],
      subject: subjectURL || '',
      city: ''
    })
    setFilterModal(false)

  }
  
  return (
    <div style={{ direction: lng === "arab" ? "rtl" : "ltr" }} className="pages_with_header">
      <ReusableBanner 
        title={
          subjectURL && subjectLoading?
          '':
          subjectURL && subject?.data?.filter(item => item.id == subjectURL).length > 0?
          subject?.data?.filter(item => item.id == subjectURL).shift()?.name:
          t("JOB OPENINGS")
          } 
        />
      <div className="all_jibs_wrapper">
        <JobsList
          typeURL={typeURL}
          subjectURL={subjectURL}
          job={jobs}
          loading={loadingSwitch}
          currentPage={currentPage}
          nextPage={nextPage}
          lastPage={lastPage}
          setInputSearch={setInputSearch}
          inputSearch={inputSearch}
          setFilterModal={setFilterModal}
          setSortBy={setSortBy}
          sortBy={sortBy}
        />
        <JobFilterModal
          show={filterModal}
          dialogClassName="modal-60w"
          onHide={() => setFilterModal(false)}
          maxsalary={maxSalary}
          handlefilter={handleFilter}
          jobFilterData={jobFilterData}
          removeFilter={removeFilter}
        />
      </div>
    </div>
  );
};

export default JobOpenings;
