import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getCitizenships, getCurriculums, getDegreeLevels, getGender, getGrades, getJobTitle, getLanguages, getSubjects } from "../../../../../redux/actions/registeration";
import { toastAlert } from "../../../../../layouts/nav/toastAlert";
import { Link } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  CountryDropdown,
  RegionDropdown,
} from "react-country-region-selector";
import Select from "react-select";
import LogoFilePicker from "../../../commonComponents/LogoFilePicker";
import ManyFilesPicker from '../../../commonComponents/ManyFilesPicker'
import upload from "../../../../../assets/upload.png";
import userPlaceHolder from "../../../../../assets/user.png";
import { Country, State, City }  from 'country-state-city';

let lng = JSON.parse(localStorage.getItem("language"))?.lang;

let academyTypes = [
  {id: '1', name: lng === 'arab'? 'مدرسة K-12':'K-12 School'},
  {id: '2', name: lng === 'arab'? 'مدرسة لغات':'Language School'},
  {id: '3', name: lng === 'arab'? 'روضة أطفال':'Kindergarten'},
  {id: '4', name: lng === 'arab'? 'مدرسة عامة':'Public School'},
  {id: '5', name: lng === 'arab'? 'مدرسة مؤجرة':'Charter School'},
  {id: '6', name: lng === 'arab'? 'مدرسة مستقلة':'Independent School'},
  {id: '7', name: lng === 'arab'? 'مدرسة دولية':'International School'},
  {id: '8', name: lng === 'arab'? 'جهة مدرسية':'School District'},
  {id: '9', name: lng === 'arab'? 'تعليم عن بعد':'Online Tutoring'},
  {id: '10', name: lng === 'arab'? 'مؤسسة ما بعد الثانوية':'Post Secondary Institution'},
  {id: '11', name: lng === 'arab'? 'شركة توظيف':'Recruitment Company'},
  {id: '12', name: lng === 'arab'? 'منظمة تطوعية':'Volunteer Organization'},
  {id: '13', name: lng === 'arab'? 'أخرى':'Other'}
]

let schoolTypes = [
  {id: 'Private', name: lng === 'arab'? 'خاصة':'Private'},
  {id: 'Public', name: lng === 'arab'? 'عامة':'Public'},
]

let internationalAccreditation = [
  {id: '1', name: lng === 'arab'? 'نحن مدرسة معتمدة':'We are an accredited school'},
  {id: '2', name: lng === 'arab'? 'نحن في طور الاعتماد':'We are in the process of being accredited'},
  {id: '3', name: lng === 'arab'? 'نحن لسنا مدرسة معتمدة':'We are not an accredited school'}
]

let city;

const AcademyStep = ({ types, setTypes, submitFirstStep, academyFiles, setAcademyFiles, setViewOuter, setAcademyRegData, acadeyRegData, setAcademyFilesInfo, academyFilesInfo, setProfileState, profileState, setProfileFile, profileFile}) => {
  const handleState = (e) => {
    setAcademyRegData({ ...acadeyRegData, state: e.target.value })
    const code = State.getStatesOfCountry("SA").find(x => x.name === e.target.value).isoCode
    city = City.getCitiesOfState("SA", code)
  }
  return (
    <>
    <div className="green-bg" />
    <form className="login_form" onSubmit={submitFirstStep}>
      <div className="regular_login">
      <div style={{width:'100%', marginRight:'0px'}} className="row">
      <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">
          {t("Academy Name")}*
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t("Success Academy")}
          value={acadeyRegData.name}
          name='name'
          onChange={(e) => setAcademyRegData({...acadeyRegData, name: e.target.value})}
          required
        />
      </div>
      <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">{t("Grades")}*</label>
        <Select
        name='types'
        onChange={(e) => setTypes(e)}
        value={types}
          options={academyTypes?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))}
          isMulti
        />
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">{t("Academy website")}*</label>
        <input
          type="text"
          className="form-control"
          placeholder={"www.example.com"}
          value={acadeyRegData.website}
          name='website'
          onChange={(e) => setAcademyRegData({...acadeyRegData, website: e.target.value})}
          required
        />
      </div>
      
      {/* <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
          <label className="text-label">
            {t("Country")}*
          </label>
          <CountryDropdown
            className="form-control"
            defaultOptionLabel={t("Select Country")}
            value={acadeyRegData.country}
            onChange={(val) =>
              setAcademyRegData({ ...acadeyRegData, country: val })
            }
          />
        </div> */}
        <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
          <label className="text-label">
            {t("State")}*
          </label>
          {/* <RegionDropdown
            className="form-control"
            country={acadeyRegData.country || "Saudi Arabia"}
            defaultOptionLabel={t("Select State")}
            value={acadeyRegData.city}
            onChange={(val) => handleCity(val)}
          /> */}
          <select value={acadeyRegData.state} onChange={(e) => handleState(e)} name='state'  className="form-control">
              <option value={''} disabled>{t("Select")}</option>
          {State.getStatesOfCountry("SA")?.map((item, i) => 
              <option key={i} value={item?.name}>{item?.name}</option>
            )}
        </select>
        </div>

        <div className="widthMedia form-group mb-3 col-md-6 col-sm-12 col-lg-6">
        <label className="text-label">{t("City")}*</label>
        <select value={acadeyRegData.city} onChange={(e) => setAcademyRegData({...acadeyRegData, city: e.target.value})} name='city'  className="form-control">
          <option value={''} disabled>{t("Select")}</option>
          {city?.map((item, i) => 
              <option key={i} value={item?.name}>{item?.name}</option>
            )}
        </select>
      </div>
        <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">
          {t("Address")}*
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t("Address")}
          value={acadeyRegData.street}
          name='street'
          onChange={(e) => setAcademyRegData({...acadeyRegData, street: e.target.value})}
          required
        />
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">{t("Organization Type")}*</label>
        <select value={acadeyRegData.school_type} onChange={(e) => setAcademyRegData({...acadeyRegData, school_type: e.target.value})} name='school_type'  className="form-control">
          {schoolTypes.map((item, i) => 
              <option key={i} value={item?.id}>{item?.name}</option>
            )}
        </select>
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">
          {t("Specialize in")}*
        </label>
        <input
          type="text"
          className="form-control"
          placeholder={t("English")}
          value={acadeyRegData.specialization}
          name='specialization'
          onChange={(e) => setAcademyRegData({...acadeyRegData, specialization: e.target.value})}
          required
        />
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label card-label">
          {t("Academy Description")}*
        </label>
        <textarea
          className="form-control"
          rows="4"
          placeholder={t("Details")}
          value={acadeyRegData.bio}
          name='bio'
          onChange={(e) => setAcademyRegData({...acadeyRegData, bio: e.target.value})}
        ></textarea>
      </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
          <label className="text-label">
            {t("Contact Number")}*
          </label>
          <PhoneInput
            id="phone"
            className="form-control"
            style={{display:'flex'}}
            placeholder={t("Enter phone number")}
            value={acadeyRegData.contact_number}
            onChange={(phone) =>
              setAcademyRegData({...acadeyRegData, contact_number: phone})
            }
          />
        </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
          <label className="text-label">
            {t("Upload Academy Logo")}*
          </label>
          <ul style={{listStyle:'none'}}>
            <li>Maximum Size 10 MB</li>
            <li>Recommended Files:- jpg, png, jpeg</li>
          </ul>
          <LogoFilePicker setProfileFile={setProfileFile} LogoState={profileState} setLogoState={setProfileState}>
            <div style={{cursor: 'pointer'}} className="userProfile">
              <img src={profileFile? profileFile:userPlaceHolder} alt='upload_icon'/>
            </div>
          </LogoFilePicker>
        </div>
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">{t("International Accreditation")}*</label>
        <select value={acadeyRegData.international_accreditation_id} onChange={(e) => setAcademyRegData({...acadeyRegData, international_accreditation_id: e.target.value})} name='international_accreditation_id'  className="form-control">
          {internationalAccreditation.map((item, i) => 
              <option key={i} value={item?.id}>{item?.name}</option>
            )}
        </select>
      </div>
      {acadeyRegData.international_accreditation_id == '1'?
      <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
        <label className="text-label">
          {t("Please list international accrediting bodies you are accredited by")}*
        </label>
        <textarea
          className="form-control"
          rows="4"
          value={acadeyRegData.international_accrediting_bodies}
          name='international_accrediting_bodies'
          onChange={(e) => setAcademyRegData({...acadeyRegData, international_accrediting_bodies: e.target.value})}
        ></textarea>
      </div>:null}
        <div className="widthMedia form-group mb-3 col-md-12 col-sm-12 col-lg-12">
          <label className="text-label">
            {t("Please upload these files")}*
          </label>
          <ul style={{listStyle:'none'}}>
            {/* <li>{t("Document Nummber 1")}</li>
            <li>{t("Document Nummber 2")}</li>
            <li>{t("Document Nummber 3")}</li> */}
            <li>Maximum Size 10 MB</li>
            <li>Recommended Files:- doc, docx, pdf, jpg, png, jpeg</li>
          </ul>
          <ManyFilesPicker 
            setAcademyFiles={setAcademyFiles}
            academyFiles={academyFiles}
            academyFilesInfo={academyFilesInfo}
            setAcademyFilesInfo={setAcademyFilesInfo}
          >
            <div style={{cursor: 'pointer'}} className="custom_file_input">
              <img src={upload} alt='upload_icon'/>
            </div>
          </ManyFilesPicker>
        </div>
        <div style={{display:'flex', gap:'7px'}} className="form-check mt-3 mb-4">
          <input style={{float:'unset', marginRight:'unset', marginLeft:'unset'}} checked={acadeyRegData.is_recognised == '1'} value={acadeyRegData.is_recognised} onChange={(e)=> setAcademyRegData({...acadeyRegData, is_recognised: acadeyRegData.is_recognised == '0'? '1':'0'})} className="form-check-input" type="checkbox" />
          <label className="form-check-label">
            {t("We are a recognised educational institution by our Federal or State/Provincial Government")}
          </label>
        </div>
        </div>
        <button
          id="loginSubmit"
          type="submit"
          className="widthMedia btn mb-2 col-md-6 col-sm-6 col-lg-6"
          style={{
            backgroundColor: "#008c77",
            color: "white",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {t("Next")}
        </button>
        <div className='haveAnAccount'>
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '20px 20px 0px 20px' }} className="widthMedia form-group col-md-12 col-sm-12 col-lg-12">
            <Link style={{ fontSize: '11px', color: 'gray' }} to="#" onClick={() => setViewOuter(0)}>{t('Back')}</Link>
          </div>
        </div>
        </div>
    </form>
    </>
  );
};

export default AcademyStep;
