import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { FOOTER_JOB_TYPES, LOADING_FOOTER_JOB_TYPES, LOADED_FOOTER_JOB_TYPES } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getFooterJobTypes = (lang) => async(dispatch) => {
    dispatch({ type: LOADING_FOOTER_JOB_TYPES });
    try {
        var { data } = await api.getFooterJobTypes(lang);
        await dispatch({ type: FOOTER_JOB_TYPES, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_FOOTER_JOB_TYPES });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}