import React, { useEffect } from 'react'
import ChooseUs from './chooseUs/ChooseUs'
import Grow from './grow/Grow'
import { JobCounter } from './jobCounter/JobCounter'
import Started from './started/Started'
import Testimonial from './testimonial/Testimonial'
import Use from './use/Use'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import { useTranslation } from 'react-i18next'
import News from '../home/news/News'
import { useDispatch, useSelector } from "react-redux";
import { getAboutUS } from '../../redux/actions/about_page'

const About = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const dispatch = useDispatch()
    const { about, loading } = useSelector(state => state.aboutUs);

    useEffect(() => {
        dispatch(getAboutUS(lng === 'arab' ? 2 : 1))
    }, [dispatch, lng])


    return (
        <div >
            <ReusableBanner title={t('About Us')} bacgroundclassName='AboutBC' />
            <Started />
            <Use />
            <ChooseUs />
            <JobCounter  {...{ about, loading }} />
            <Testimonial {...{ about, loading }} />
        </div>
    )

}

export default About
