import { FETCH_JOB, JOB_LOADED, LOAD_JOB } from '../../constants/actionTypes'

export default (job = {job: [], currentPage: 1, nextPage: 1, lastPage: 1, loading: false}, action) => {
    switch (action.type) {
        case FETCH_JOB:
            return { 
                ...job, 
                job: action.data.data, 
                currentPage: action.data.current_page, 
                nextPage: 
                    action.data.current_page === action.data.last_page? 
                    action?.data?.current_page:
                    action.data.last_page > action.data.current_page?
                    action?.data?.current_page + 1:
                    action.data.last_page, 
                lastPage: action.data.last_page, 
                loading: false 
            };
        case LOAD_JOB:
            return { ...job, loading: true };
        case JOB_LOADED:
            return { ...job, loading: false };
        default:
            return job;
    }
}