import { FETCH_JOB, JOB_LOADED, LOAD_JOB } from '../constants/actionTypes'
import * as api from '../../api'
import checkErr from './api_error_func';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { getIncreaseViews } from './increase_views';
export const getJobs = (pageNumber, lang, search, jobFilterData, setLoadingSwitch) => async (dispatch) => {
    dispatch({ type: LOAD_JOB });
    try {
        let teacher_categories = []
        for(const item in jobFilterData.teacherCategory) {
            teacher_categories.push(jobFilterData.teacherCategory[item]['value'])
        }
        const { data } = await api.getJobs(pageNumber, lang, search, jobFilterData.subject, jobFilterData.experience, jobFilterData.postDate, {min: jobFilterData.minimum_salary, max: jobFilterData.maximum_salary}, teacher_categories, jobFilterData.grade, jobFilterData.city );
        if (setLoadingSwitch) {
            setLoadingSwitch(false)
        }
        dispatch({ type: FETCH_JOB, data: data?.data })
    } catch (error) {
        if (setLoadingSwitch) {
            setLoadingSwitch(false)
        }
        dispatch({ type: JOB_LOADED })
    }
}
export const getJobDetails = (id, setLoadingModalShow, setJobData, lng) => async (dispatch) => {
    try {
        setLoadingModalShow(true)
        const { data } = await api.getJobDetails(id, lng);
        dispatch(getIncreaseViews(id))
        setJobData(data.data)
        setLoadingModalShow(false)
    } catch (error) {
        checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
}

export const applyForJob = (job_id, setLoadingModalShow, setActionModal, setDataTransfer, setJobDetails) => async (dispatch) => {
    try {
        var {data} = await api.applyForJob(job_id)
        setJobDetails(data.data)
        setLoadingModalShow(false);
        toastAlert(data?.message, undefined, "success");
    } catch (error) {
        checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
}

export const saveJob = (job_id, setLoadingModalShow, setJobDetails) => async (dispatch) => {
    try {
        var {data} = await api.saveJob(job_id)
        setJobDetails(data.data)
        setLoadingModalShow(false);
        toastAlert(data?.message, undefined, "success");
    } catch(error) {
        checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
}

export const unSaveJob = (job_id, setLoadingModalShow, setJobDetails) => async (dispatch) => {
    try {
        var {data} = await api.unSaveJob(job_id)
        setJobDetails(data.data)
        setLoadingModalShow(false);
        toastAlert(data?.message, undefined, "success");
    } catch(error) {
        checkErr(error, setLoadingModalShow, toastAlert, false, dispatch);
    }
}