import { FETCH_SINGLE_ARTICLE, SINGLE_ARTICLE_LOADED, LOAD_SINGLE_ARTICLE } from '../constants/actionTypes'

export default (singleSrticle = { singleSrticle: {}, loading: false }, action) => {
    switch (action.type) {
        case FETCH_SINGLE_ARTICLE:
            return { ...singleSrticle, singleSrticle: action.payload, loading: false };
        case LOAD_SINGLE_ARTICLE:
            return { ...singleSrticle, loading: true };
        case SINGLE_ARTICLE_LOADED:
            return { ...singleSrticle, loading: false };
        default:
            return singleSrticle;
    }
}