/* eslint-disable */

import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import { TRANSLATIONS_ARAB } from "./translations/arab/translations";
import { initReactI18next } from "react-i18next";
import i18n, { use } from "i18next";
import Home from "./components/home/Home";
import Header from "./components/ui/header/Header";
import Footer from "./components/ui/footer/Footer";
import Pricing from "./components/pricing/Pricing";
import About from "./components/aboutUs/AboutUs";
import Faq from "./components/faq/Faq";
// import Teacher from './components/teacher/Teacher';
import School from "./components/school/School";
import JobOpenings from "./components/jobOpenings/JobOpenings";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import ContactUs from "./components/contactUs/ContactUs";
import Blog from "./components/blog/Blog";
import BlogDetails from "./components/blogDetail/BlogDetail";
import JobDetails from "./components/jobDetails/JobDetails";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import LinkedInRedirect from './components/auth/socialMediaTools/LinkedInRedirect/LinkedInRedirect'
import { Redirect } from "react-router-dom";
import {
  getArticles,
  getAvailableJobs,
  getSubjects,
  getBanners,
  getJobLevel,
} from "./redux/actions/home_page";
import { getDegreeLevels } from "./redux/actions/registeration";
import { getJobs } from "./redux/actions/jobs";
import AuthProcess from "./components/auth/authProcess/AuthProcess";
import { getUserInfo } from "./redux/actions/Auth";
import DynamicJobsOpenings from './components/jobs/JobOpenings'
import AcademyProfile from './components/academyProfile/AcademyProfile'
import Subject from "./components/subject/Subject";
import { getPopularJob } from "./redux/actions/popular_jobs";
import { getMaxSalary } from "./redux/actions/maximum_salary";
import { getPopularCities } from "./redux/actions/popularCities";
import { getFooterJobTypes } from "./redux/actions/footerJobTypes";

const resources = {
  arab: {
    translation: TRANSLATIONS_ARAB,
  },
};
let languageFromLocal = JSON.parse(localStorage.getItem("language"));
i18n.use(initReactI18next).init({
  resources,
  lng: languageFromLocal ? languageFromLocal.lang : "arab",
});

let allTextTypes = [
  "label",
  "a",
  "button",
  "div",
  "li",
  "p",
  "text",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "span",
  "input",
];

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [authStatus, setAuthStatus] = useState(0);
  const userDetails = useSelector((state) => state?.userDetails?.token);
  const { maxSalary, loading } = useSelector(state => state.maxSalary)

  /*
  0  teacher/academy and active email and active account
  1  academy and active email and not active account
  2  normal user and active email
  3  normal user and not active email
  */
  useEffect(() => {
    if ($(".select")) {
      $(".select").niceSelect();
    }
  }, [location]);
  useEffect(() => {
    for (let i = 0; i < allTextTypes.length; i++) {
      let allElements = document.getElementsByTagName(allTextTypes[i]);
      for (let j = 0; j < allElements.length; j++) {
        allElements[j].style.fontFamily = languageFromLocal?.lang
          ? languageFromLocal?.lang === "arab"
            ? "cairo"
            : "Poppins"
          : "Poppins";
      }
    }
  });
  //Language Intialization.
  const [langNumber, setLangNumber] = useState(
    JSON.parse(localStorage.getItem("language"))?.lang === "arab" ? 1 : 2
  );
  const [changeOfLanguage, setChangeOfLanguage] = useState(0);
  let languageFromLocal = JSON.parse(localStorage.getItem("language"));
  let userTokin = localStorage.getItem("sess");

  useEffect(() => {
    if (localStorage.getItem("sess")) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${userTokin}`;
      dispatch(getUserInfo())
    }
    dispatch(getArticles(languageFromLocal?.lang === "arab" ? 2 : 1));
    dispatch(getAvailableJobs(languageFromLocal?.lang === "arab" ? 2 : 1));
    dispatch(getSubjects(languageFromLocal?.lang === "arab" ? 1 : 2));
    dispatch(getBanners(languageFromLocal?.lang === "arab" ? 2 : 1));
    dispatch(getMaxSalary())
    dispatch(getPopularCities(languageFromLocal?.lang === "arab" ? 1 : 2))
    dispatch(getDegreeLevels(languageFromLocal?.lang === "arab" ? 1 : 2))
    dispatch(getFooterJobTypes(languageFromLocal?.lang === "arab" ? 1 : 2))
    // dispatch(getJobLevel(languageFromLocal?.lang === "arab" ? 2 : 1));
    // dispatch(getJobs(1, languageFromLocal?.lang === "arab" ? 2 : 1));
    dispatch(getPopularJob(languageFromLocal?.lang === "arab" ? 1 : 2));
  }, [dispatch, languageFromLocal?.lang, userDetails]);

  if (!languageFromLocal) {
    setChangeOfLanguage(changeOfLanguage + 1);
    localStorage.setItem("language", JSON.stringify({ lang: "en" }));
  }

  useEffect(() => {
    languageFromLocal = JSON.parse(localStorage.getItem("language"));
  }, [changeOfLanguage]);
  useEffect(() => {
    if (languageFromLocal) {
      i18n.changeLanguage(languageFromLocal.lang);
      if (languageFromLocal.lang === "arab") {
        setLangNumber(1);
        $("body").addClass('arabic_direction')
      } else {
        setLangNumber(2);
      }
    }
  }, [changeOfLanguage]);

  return (
    <>
      <Switch>
      <div className="App" style={{ fontFamily: languageFromLocal?.lang === 'arab' ? 'cairo' : 'Poppins' }} >
      <Header />
      <div className="Layout">
        <Route path="/authentication" component={AuthProcess} exact />
        <Route path="/linkedIn" component={LinkedInRedirect} />
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} exact />
        <Route path="/schools" component={School} exact />
        <Route path="/jobs" component={JobOpenings} exact />
        <Route path="/contact-us" component={ContactUs} exact />
        <Route path="/blogs" component={Blog} exact />
        <Route path="/blog-details/:id" component={BlogDetails} exact />
        <Route path="/job-details/:id" component={JobDetails} exact />
        <Route path="/academy-profile/:id" component={AcademyProfile} exact />
        <Route path="/subjects" component={Subject} exact />
        {/* <Route render={() => <Redirect to={{ pathname: "/" }} />} /> */}
        {/* <Route path="/privacy-policy" component={PrivacyPolicy} exact /> */}
        {/* <Route path="/faq" component={Faq} exact /> */}
        {/* <Route path="/pricing" component={Pricing} exact /> */}
      </div>
      <Footer />
    </div> 
    </Switch>
    </>

  );
}

export default App;
