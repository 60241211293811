import React from 'react'
import './NewsCom.css'
import defaultWriter from '../../../../assets/defaultData/writer2.jpg'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const NewsCom = (props) => {
    const history = useHistory()
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <div className="blog-card">
            <div className="blog-img">
                <Link to={`/blog-details/${props?.data?.id}`}>
                    <img src={props?.data?.image || defaultWriter} className='blog_img' alt="blog" />
                </Link>
            </div>
            <div className="blog-text">
                <h3 style={{ textAlign: lng === 'arab' ? 'right' : 'left', fontSize:'16px' }}>
                    <a style={{fontWeight:'bold'}} onClick={() => history.push(`/blog-details?id=${props?.data?.id}`)}>
                        {props?.data?.title || t('How to Indroduce in Yourself in Job Interview?')}
                    </a>
                </h3>
                <p style={{ textAlign: lng === 'arab' ? 'right' : 'left', fontSize:'13px' }} className='blog_content'>{props?.data?.body?.slice(0,109) + (props?.data?.body?.length > 111? ('...'):'')}</p>
            </div>
        </div> 
    )
}

export default NewsCom