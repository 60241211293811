import React, {useEffect, useState} from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import ContactForm from './contactForm/ContactForm'
import ContactSection from './contactSection/ContactSection'
import { useTranslation } from 'react-i18next'
import * as api from '../../api'
import { toastAlert } from '../../layouts/nav/toastAlert'
import Spinner from '../ui/spinner/Spinner'
import LoadingModal from '../../layouts/LoadingPopUp'
const ContactUs = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const [lodaing, setLoading] = useState(true)
    const [loadingModalShow, setLoadingModalShow] = useState(false)
    const [contactUsInfo, setContactUsInfo] = useState({})
    const [contactUsForm, setContactUsForm] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    })
    useEffect(() => {
        let mounted = true
        api.getContactUsInfo(lng==='arab'? 1:2).then((res) => {
            if (mounted) {
                console.log(res?.data?.data)
                setLoading(false)
                setContactUsInfo(res?.data?.data)
            }
        }).catch((e) => {
            if (mounted) {
                setLoading(false)
                toastAlert("Something went wrong...!", undefined, "error");
              }
        }).finally(() => {
            if (mounted) {
                setLoading(false);
            }
          });
          return () => {
            mounted = false
          }
      }, []);
    const submitContactUs = (e) => {
        e.preventDefault()
        setLoadingModalShow(true)
        api.submitContactForm(contactUsForm.name, contactUsForm.email, contactUsForm.phone, contactUsForm.subject, contactUsForm.message).then((res) => {
            setLoadingModalShow(false)
            toastAlert(res.data.message, undefined, "success");
        }).catch((e) => {
            setLoadingModalShow(false)
            toastAlert("Something went wrong...!", undefined, "error");
        }).finally(() => {
            setLoadingModalShow(false)
        });
      }
    return (
        <div style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
            <ReusableBanner title={t("Contact Us")} />
            {lodaing?
            <Spinner/>:
            <>
            <ContactSection contactUsInfo={contactUsInfo}/>
            <ContactForm contactUsForm={contactUsForm} setContactUsForm={setContactUsForm} submitContactUs={submitContactUs}/>
            </>
            }
            <LoadingModal
                show={loadingModalShow}
                dialogClassName="modal-60w"
                backdrop="static"
                onHide={() => setLoadingModalShow(false)}
            />
        </div>
    )
}

export default ContactUs