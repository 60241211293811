import React from 'react'
import { useTranslation } from 'react-i18next'

const Use = () => {
    const { t } = useTranslation();
    return (
        <section className="use-section pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <h2>{t("Easiest Way To Use")}</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="use-text">
                            <span>{t("1")}</span>
                            <i className='flaticon-website'></i>
                            <h3>{t("Browse Job")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor")}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="use-text">
                            <span>{t("2")}</span>
                            <i className='flaticon-recruitment'></i>
                            <h3>{t("Find Your Vaccancy")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor")}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div className="use-text">
                            <span>{t("3")}</span>
                            <i className='flaticon-login'></i>
                            <h3>{t("Submit Resume")}</h3>
                            <p>{t("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Use