import { FETCH_JOB_LEVEL, JOB_LEVEL_LOADED, LOAD_JOB_LEVEL } from '../../constants/actionTypes'

export default (job = { job: [], loading: false }, action) => {
    switch (action.type) {
        case FETCH_JOB_LEVEL:
            return { ...job, job: action.payload, loading: false };
        case LOAD_JOB_LEVEL:
            return { ...job, loading: true };
        case JOB_LEVEL_LOADED:
            return { ...job, loading: false };
        default:
            return job;
    }
}