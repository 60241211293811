import React from 'react'
import ReusableBanner from '../ui/reusableBanner/ReusableBanner'
import News from '../home/news/News'
import { useTranslation } from 'react-i18next'

const Blog = () => {
    const { t } = useTranslation();
    return (
        <div className="pages_with_header">
            <ReusableBanner title={t("BLOG")} />
            <News fromNewsPage/>
        </div>
    )
}

export default Blog