import { GENDER, LOADING_GENDER, LOADED_GENDER } from "../../constants/actionTypes";

export default (gender = { gender: [], loading: false }, action) => {
    switch (action.type) {
        case GENDER:
            return { ...gender, gender: action.data, loading: false };
        case LOADING_GENDER:
            return { ...gender, loading: true };
        case LOADED_GENDER:
            return { ...gender, loading: false };
        default:
            return gender;
    }
}