import React, { useState } from 'react'
import SlideOne from './SlideOne'
import loginImg from "../../../../../assets/authBC.png";
import LoadingModal from '../../../../../layouts/LoadingPopUp';
import { t } from 'i18next'

const SignUp = (props) => {
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <div className="login_page">
            <div className='login_card_wrapper'>
                <h3 className="page_title" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>{t("SIGN UP")}</h3>
                <div className='cardd overflow-hidden'>
                    <div style={{padding: '20px'}} className="card-body innerCardBody">
                        <div className="green-bg" />
                        <SlideOne setView={props.setView} setLoadingModalShow={props.setLoadingModalShow} />
                    </div>
                </div>
            </div>
            <LoadingModal
            show={loadingModalShow}
            dialogClassName="modal-60w"
            backdrop="static"
            onHide={() => setLoadingModalShow(false)}
            />
        </div>
    )
}

export default SignUp