import * as api from "../../api";
import { toastAlert } from "../../layouts/nav/toastAlert";
import { AUTH, FORGOT_PASSWORD, LOGOUT, AUTH_SUBTYPE, LOADING_USER_INFO, LOADED_USER_INFO } from "../constants/actionTypes";
import checkErr from "./api_error_func";
import { t } from "i18next";

export const signIn =
  (email, password, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var userData = await api.Login(email, password);
      // setLoadingModalShow(false);
      toastAlert(t("You Logged In Successfully"), undefined, "success");
      await dispatch({ type: AUTH, data: userData?.data?.data?.jwt });
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

export const signInWithSocial =
  (loginType, accessToken, setLoadingModalShow, history) =>
  async (dispatch) => {
    try {
      var userData = await api.socialLogin(loginType, accessToken);
      setLoadingModalShow(false);
      toastAlert(t("You Logged In Successfully"), undefined, "success");
      await dispatch({ type: AUTH, data: userData?.data?.data?.jwt });
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert);
    }
  };

  export const forgotPassword = (email, setLoadingModalShow, history) => async (dispatch) => {
    try {
      var forgotPasswordData = await api.forgotPassword(email);
      setLoadingModalShow(false);
      toastAlert(t("Email has been sent successfully"), undefined, "success");
      await dispatch({ type: FORGOT_PASSWORD, data: forgotPasswordData })
      // history.push('/login')
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert)
    }
  }

  export const resetPassword = (password, password_confirmation, token, setLoadingModalShow, history, setShowMessage) => async (dispatch) => {
    try {
      var resetPasswordData = await api.resetPassword(password, password_confirmation, token);
      setLoadingModalShow(false);
      setShowMessage(true)
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, history.push('/'))
    }
  }


  export const signUpregular = (email, password, setLoading, setView) => async (dispatch) => {
    try {
      var userData = await api.signUp(email, password);
      setLoading(false)
      toastAlert(userData?.data?.message, undefined, "success");
      await dispatch({ type: AUTH, data: userData?.data?.data?.jwt });
    } catch (error) {
      checkErr(error, setLoading, toastAlert)
    }
  };

  export const getUserInfo = (lng) => async (dispatch) => {
     await dispatch({ type: LOADING_USER_INFO});
    try {
        var { data } = await api.getUserInfo(lng);
        await dispatch({ type: AUTH_SUBTYPE, data: data.data});
    } catch (error) {
      dispatch({ type: LOADED_USER_INFO});
      checkErr(error, false, toastAlert, false, dispatch);
    }
  };

  export const checkValidation = (email, setLoadingModalShow, history) => async (dispatch) => {
    try {
        var { data } = await api.checkValidation(email);
          setLoadingModalShow(false)
          if (data.status) {
            window.location.reload()
          }
    } catch (error) {
      setLoadingModalShow(false)
    }
  };

  export const reSendVerf = (email, setLoadingModalShow, history) => async (dispatch) => {
    try {
        var { data } = await api.reSendVerf(email);
          setLoadingModalShow(false)
          if (data.status) {
            toastAlert(t("Sent!"), undefined, "success");
          }
    } catch (error) {
      checkErr(error, setLoadingModalShow, toastAlert, window.location.reload())
    }
  };

  export const logoutActoin = (setLoadingModalShow, history) => async (dispatch) => {
    if(setLoadingModalShow) {
      setLoadingModalShow(true)
    }
    try {
        var lougoutData = await api.logoutAPI();
        if (setLoadingModalShow) {
          setLoadingModalShow(false)
        }
          dispatch({type: LOGOUT})
    } catch (error) {
      checkErr(error, setLoadingModalShow)

    }
  };