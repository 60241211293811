import { LICENSE, LOADING_LICENSE, LOADED_LICENSE } from "../../constants/actionTypes";

export default (licence = { licence: [], loading: false }, action) => {
    switch (action.type) {
        case LICENSE:
            return { ...licence, licence: action.data, loading: false };
        case LOADING_LICENSE:
            return { ...licence, loading: true };
        case LOADED_LICENSE:
            return { ...licence, loading: false };
        default:
            return licence;
    }
}