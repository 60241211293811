import { SUBJECT_DETAILS, LOADED_SUBJECT_DETAILS, LOADING_SUBJECT_DETAILS } from '../../constants/actionTypes'

export default (subjectDetails = { subject: [], loading: false }, action) => {
    switch (action.type) {
        case SUBJECT_DETAILS:
            return { ...subjectDetails, subject: action.data, loading: false };
        case LOADING_SUBJECT_DETAILS:
            return { ...subjectDetails, loading: true };
        case LOADED_SUBJECT_DETAILS:
            return { ...subjectDetails, loading: false };
        default:
            return subjectDetails;
    }
}