import axios from 'axios';
import { AUTH, LOGOUT, LOADING_USER_INFO, AUTH_SUBTYPE, LOADED_USER_INFO } from '../../constants/actionTypes';
import { logOutFromFB } from './LogOutFacebook';

export default (userDetails = { auth: false, authData: {}, token: "", loading: true }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem("sess", action?.data);
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.data}`;
            return { ...userDetails, auth: true, token: action?.data};
        case LOGOUT:
            localStorage.removeItem("sess");
            logOutFromFB();
            window.FB?.logout();
            window.location.reload()
            return { ...userDetails, auth: false, authData: {}, token: "" };
        case AUTH_SUBTYPE:
            return { ...userDetails, authData: action?.data, loading: false };
        case LOADING_USER_INFO:
            return { ...userDetails, loading: true };
        case LOADED_USER_INFO:
            return { ...userDetails, loading: false };
        default:
        return userDetails;
    }
}