import {
    POPULAR_CITIES,
    LOADING_POPULAR_POPULAR_CITIES,
    LOADED_POPULAR_POPULAR_CITIES,
  } from "../../constants/actionTypes";
  
  export default (popularCities = { popularCities: [], loading: false }, action) => {
    switch (action.type) {
      case POPULAR_CITIES:
        return { ...popularCities, popularCities: action.data, loading: false };
      case LOADING_POPULAR_POPULAR_CITIES:
        return { ...popularCities, loading: true };
      case LOADED_POPULAR_POPULAR_CITIES:
        return { ...popularCities, loading: false };
      default:
        return popularCities;
    }
  };
  