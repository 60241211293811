import React from 'react'
import { t } from 'i18next'
import loginImg from '../../../../assets/authBC.png'
const Welcome = ({name}) => {
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <div className="login_page">
      <div className='login_card_wrapper'>
        <div className='cardd overflow-hidden'>
          <div style={{padding: '20px'}} className="card-body innerCardBody">
        <div className="green-bg" />
        <div  className="login_form" style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }}>
          <div style={{minHeight: '200px', display:'flex', alignItems:'center', justifyContent:'center'}} className='regular_login'>
            <h5 style={{color: 'gray', textAlign:'center'}}>{t("Welcome") + t(', ') + name}</h5>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  )
}

export default Welcome