import { GRADES, LOADING_GRADES, LOADED_GRADES } from "../../constants/actionTypes";

export default (grades = { grades: [], loading: false }, action) => {
    switch (action.type) {
        case GRADES:
            return { ...grades, grades: action.data, loading: false };
        case LOADING_GRADES:
            return { ...grades, loading: true };
        case LOADED_GRADES:
            return { ...grades, loading: false };
        default:
            return grades;
    }
}