import * as api from '../../api/index';
import { toastAlert } from '../../layouts/nav/toastAlert';
import { POPULAR_JOB_TITLES, LOADING_POPULAR_JOB_TITLES, LOADED_POPULAR_JOB_TITLES } from '../constants/actionTypes';
import checkErr from './api_error_func'

export const getPopularJob = (lang) => async(dispatch) => {
    dispatch({ type: LOADING_POPULAR_JOB_TITLES });
    try {
        var { data } = await api.getPopularJobTitle(lang);
        await dispatch({ type: POPULAR_JOB_TITLES, data: data?.data })
    } catch(error) {
        dispatch({ type: LOADED_POPULAR_JOB_TITLES });
        checkErr(error, false, toastAlert, false, dispatch);
    }
}