import React from 'react'
import { useTranslation } from 'react-i18next'

const Started = () => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    return (
        <section style={{ direction: lng === 'arab' ? 'rtl' : 'ltr' }} className="about-section ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-text">
                            <div className="about-us-section">
                                <h2>{t("How We Started")}</h2>
                            </div>
                            <div>
                                <p>{t("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.")}</p>
                            </div>
                            <div>
                                <p>{t("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-img">
                            <img src={require("../../../assets/img/about-us/about.jpeg")} alt="about image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Started
