import React from 'react'
import './CreateBuild.css'
import notify from '../../../assets/defaultData/teacher.png'
import notifyAr from '../../../assets/teacherAr.png'
import sectionImg from '../../../assets/section2.svg'
import wave from '../../../assets/wave.svg'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const CreateBuild = (props) => {
  const { t } = useTranslation();
  let lng = JSON.parse(localStorage.getItem("language"))?.lang;
  return (
    <section style={{direction: lng === 'arab'? 'rtl': 'ltr'}} className='create_banner'>
      <div className='create_left_content'>
        <h1 style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Create better outstanding profile")}</h1>
        <p style={{textAlign: lng === 'arab'? 'right':'left'}}>{t("Create an account so you can begin creating and boosting your profile. Enter all your experience and skills to find your job.")}</p>
        <Link to={'/authentication'}>
          {props.userDetails?.type === 'T'? t("Dashboard"):t("Sign up")}
          <svg style={{transform: lng === 'arab'? 'rotate(180deg)':null}} xmlns="http://www.w3.org/2000/svg" width="16.522" height="16.522" viewBox="0 0 16.522 16.522">
            <path className='banner_btn_arrow' id="Icon_ionic-ios-arrow-dropup-circle" data-name="Icon ionic-ios-arrow-dropup-circle" d="M8.261,16.522A8.261,8.261,0,1,0,0,8.261,8.26,8.26,0,0,0,8.261,16.522Zm0-9.711L5.04,10.064A.767.767,0,0,1,3.956,8.98l3.8-3.789a.766.766,0,0,1,1.056-.024L12.558,8.9a.765.765,0,1,1-1.08,1.084Z" transform="translate(16.522) rotate(90)" fill="#fff"/>
          </svg>
        </Link>
        <div className='create_circle_shadow'>
          <img src={wave} alt='circle_shadow'/>
        </div>
      </div>
      <div className='create_right_content'>
        <img src={sectionImg} alt="new_banner" className='new_banner'/>
      </div>
    </section>
  )
}

export default CreateBuild