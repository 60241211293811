import React from 'react'
import { useTranslation } from 'react-i18next'
import { translate } from '../../../helper/index'
import Spinner from '../spinner/Spinner'
import NoDataFound from '../noDataFound/NoDataFound'
import Pagination from '../pagination/Pagination'
import defaultAcademy from '../../../assets/defaultAcademy.svg'
import Select from "react-select";
import { useHistory } from 'react-router-dom'

const List = ({ school, loading, search, type, teacher, currentPage, nextPage, lastPage, cityURL, setSearchState, searchState, popularCities }) => {
    const { t } = useTranslation();
    let lng = JSON.parse(localStorage.getItem("language"))?.lang;
    const history = useHistory()
    return (
        <section className="teacher pb-70 blog-style-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 order-lg-1">
                        <div className="row mt-5 mb-3">
                            <div className="col-lg-8 col-md-9 col-sm-8 mb-3">
                                <label className="form-label mt-2">{t(search)}</label>
                                <input value={searchState} onChange={(e) => setSearchState(e.target.value)} type="text" id="disabledTextInput" className="form-control" placeholder={t("Academy Name")} />
                            </div>
                            <div className="col-lg-4 col-md-3 col-sm-4 mb-3">
                                <label className="form-label mt-2">{t("Location")}</label>
                                <Select
                                    name="types"
                                    onChange={(e) => history.push(`/schools?cit=${e.value}`)}
                                    placeholder={t("Select City")}
                                    options={popularCities?.map((item) => ({
                                        label: item?.city,
                                        value: item?.city,
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-sm-12 order-lg-2">
                    {loading ?
                        <Spinner />
                        :
                        <div className="row">
                            {school?.length === 0 ?
                            <NoDataFound />
                            :
                            school?.map((item, index) => (
                            <div style={{cursor:'pointer'}} onClick={() => history.push(`/academy-profile/${item?.id}`)} className="col-lg-3 col-sm-6 p-3" key={index}>
                                <div className="d-flex align-items-center flex-column border p-3 my-shadow">
                                    <div className="logo">
                                        <img src={item.avatar || defaultAcademy} alt="company logo" className="rounded-circle w-100 h-100" />
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <h5 style={{ fontWeight: '900', fontSize: '14px' }} className="fw-bold"> {item?.name} </h5>
                                        <p style={{ fontWeight: '600', fontSize: '12px' }}> {item?.location?.city || t("N/A")} </p>
                                        <p style={{ fontWeight: '200', fontSize: '11px' }}> {item?.school_type || t("N/A")} </p>
                                    </div>
                                </div>
                            </div>
                            ))
                            }
                        </div>
                    }
                    </div>
                </div>
                {lastPage === 1?
                null:
                <Pagination
                    currentPage={currentPage}
                    nextPage={nextPage}
                    lastPage={lastPage}
                    paramURL={cityURL}
                    school={school}
                    loading={loading}
                />
                }
                
            </div>
        </section >
    )
}

export default List