import axios from "axios";

export const createTeacher = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if (
      item === "languages" ||
      item === "citizenships"
    ) {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]['value']);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createTeacher`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const getJobTitles = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/jobTitle?lang=${langNumber}`);

export const getGender = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/gender?lang=${langNumber}`);

export const getLanguages = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/language?lang=${langNumber}`);

export const getCitizenships = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/citizenship?lang=${langNumber}`);

export const getDegreeLevels = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/degreeLevel?lang=${langNumber}`);

export const getCurriculums = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/curriculum?lang=${langNumber}`);

export const getGrades = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/grade?lang=${langNumber}`);

export const getSubjects = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/subject?lang=${langNumber}`);

export const getLicenseTypes = async (langNumber) =>
  await axios.post(`https://api.madaresweb.mvp-apps.ae/api/teachingLicenseType?lang=${langNumber}`);


export const teacherEducation = async (userData) => {
    let finalFormData = new FormData();
    for (let i = 0; i < userData.length; i++) {
      for (const item in userData[i]) {
        finalFormData.append(
          `educations[${i}][${item}]`,
          userData[i][item]
      );
      }
    }
    return await axios({
      method: "post",
      url: `https://api.madaresweb.mvp-apps.ae/api/education/store`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      mode: "no-cors",
      data: finalFormData,
    });
};
           
export const teacherExperience = async (userData) => {
  let finalFormData = new FormData();
  for (let i = 0; i < userData.length; i++) {
    for (const item in userData[i]) {
      if (item === "grades" || item === "subjects") {
        for (let j = 0; j < userData[i][item].length; j++) {
          finalFormData.append(`experiences[${i}][${item}][${j}]`, userData[i][item][j]['value']);
        }
      } else {
        finalFormData.append(`experiences[${i}][${item}]`, userData[i][item]);
      }
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/experience/store`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const teachingLicense = async (userData) => {
  let finalFormData = new FormData();
  for (let i = 0; i < userData.length; i++) {
    for (const item in userData[i]) {
      if (item === "grades" || item === "subjects") {
        for (let j = 0; j < userData[i][item].length; j++) {
          finalFormData.append(`licenses[${i}][${item}][${j}]`, userData[i][item][j]['value']);
        }
      } else {
        finalFormData.append(`licenses[${i}][${item}]`, userData[i][item]);
      }
    }
  }
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/teachingLicense/store`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};

export const createAcademy = async (userData) => {
  let finalFormData = new FormData();
  for (const item in userData) {
    if(item === "types") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]['value']);
      }
    }
    if (item === "academy_files") {
      for (let i = 0; i < userData[item].length; i++) {
        finalFormData.append(`${item}[${i}]`, userData[item][i]);
      }
    } else {
      finalFormData.append(item, userData[item]);
    }
  }
  finalFormData.delete('types');
  return await axios({
    method: "post",
    url: `https://api.madaresweb.mvp-apps.ae/api/createAcademy`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    mode: "no-cors",
    data: finalFormData,
  });
};