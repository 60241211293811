import { FETCH_ALL_SIZES, LOAD_SIZES, LOADED_SIZES } from '../../constants/actionTypes'
export default (sizes = {sizes: [], loading: false}, action) => {
    switch (action.type) {
        case FETCH_ALL_SIZES:
            return { ...sizes, sizes: action.data, loading: false };
        case LOAD_SIZES:
            return { ...sizes, loading: true };
        case LOADED_SIZES:
            return { ...sizes, loading: false };
        default:
            return sizes;
  }
}